import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ArrowLeft, ChevronLeft, ForkLeft } from "@mui/icons-material";
import Logo_1 from "../assets/images/Logo_1.png";
import SwipeableTemporaryDrawer from "../components/Drawer";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { theme } from "../theme";
const nav_Links = [
  { title: "الصفحة الرئيسية", url: "/" },
  { title: "عن البرنامج", url: "/about-project" },
  // {title:'اخر الاخبار',url:'/aboutus'},
  { title: "تواصل معنا", url: "/contactus" },
  { title: "نبذة عنا", url: "/aboutus" },
];
export default function ButtonAppBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  return (
    <Box>
      <Box display="flex" justifyContent="space-evenly" height={150}>
        {/* <Toolbar > */}

        <IconButton size="large" edge="start" color="inherit" aria-label="menu">
          {/* <ChevronLeft fontSize="large"/> */}
        </IconButton>
        <Box
          component="div"
          onClick={() => navigate("/")}
          sx={{ alignSelf: "flex-end" }}
        >
          {location.pathname == "/" ? null : <img src={Logo_1} width="200" />}
        </Box>
        <IconButton
          size="large"
          // edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setOpen(true)}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        {/* </Toolbar> */}
      </Box>
      {/* <SwipeableTemporaryDrawer /> */}
      <Drawer
        variant="temporary"
        sx={{
          ".MuiDrawer-paper": {
            height: "55vh",
            marginTop: "calc(0.2*100vh)",
            borderTopLeftRadius: 25,
            borderBottomLeftRadius: 25,
            backgroundColor: '#7ad8daa4',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center'
          },
        }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{ width: 300,  }}
          role="presentation"
          // onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
        >
          <List>
            {nav_Links.map((item, index) => (
              <ListItem
                key={item.url}
                onClick={() => {
                  navigate({ pathname: item.url });
                  setOpen(false);
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  </ListItemIcon>
                  <ListItemText sx={{ color: "white" }}   primaryTypographyProps={{
                    fontSize: 22,
                    textAlign:'center'
                  }} primary={item.title} />
                </ListItemButton>
                <Divider />
              </ListItem>
            ))}
          </List>

          {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
          {/* </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem> */}
          {/* ))}
      </List> */}
        </Box>
      </Drawer>
    </Box>
  );
}
