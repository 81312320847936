import { Box, FormLabel, InputLabel } from "@mui/material";
import React from "react";
// import Logo_1 from '../../../../assets/images/photo_png1.png'
import text_1 from "../../../../demo/text";
import { theme } from "../../../../theme";

const Item_5 = ({ title, img,img1, txt3, mb, txt, txt2, ms, as,nb }) => {
  return (
    <Box
      sx={{
        alignSelf: { xs: "", sm: "", md: as, lg: as },
        width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
        mt: mb ? 1 : ms && 2,
        gap:0.5,
        display:'flex',flexDirection:'column'
      }}
    >
      <InputLabel sx={{color:theme.palette.secondary.main,textAlign:'center',fontSize:20}} >{txt}</InputLabel>
                <Box
                  sx={{
                    width: 100,
                    borderWidth: 2,
                    borderColor: theme.palette.primary.main,
                    borderStyle: "solid",
                    borderRadius: 5,
                    m: 2,
                    pt:2,
                    textAlign:'center',
                    alignSelf:'center'

                  }}
                >
      <FormLabel sx={{ backgroundColor:theme.palette.secondary.main,borderRadius:20,color:'white',padding:nb==2 ? '7px 18px' : '7px 14px',paddingTop:1.7,textAlign:'center'}}>{5}</FormLabel>
                  <InputLabel
                    sx={{
                      fontSize: 24,
                      color: theme.palette.secondary.main,
                      textAlign:'center'
                    }}
                  >
                    {txt3}
                  </InputLabel>
                </Box>
    </Box>
  );
};

export default Item_5;
