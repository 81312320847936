import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../util/API";


const initialState = {
data:[],
loading:false,
error:false
};

export const getSchool = createAsyncThunk(
    "shcoolsSlice/get",
    async (id) => {
      try {
        let res = await API.get('getschools')
       if(res.data.success){
         return res.data.data
       }
      } catch (err) {
        if (!err.response) {
          throw err;
        }
        console.log(err);
      }
    }
  )

export const shcoolsSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
  },
  extraReducers: {
    [getSchool.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getSchool.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getSchool.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    }
  },
});

export const {  } = shcoolsSlice.actions;

export default shcoolsSlice.reducer;
