import { AccountCircle, Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Hidden,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import _Button from "../../components/Button";
import { theme } from "../../theme";
import photo_png3 from '../../assets/images/photo_png3.png'
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../features/authSlice";
import { Link as brLink } from "react-router-dom";
let style = {
  m: 1,
  borderRadius: 10,
  backgroundColor: theme.palette.primary.main,
  marginBottom:'5%'
};
let style1 = {
  "& .MuiInputBase-input": {width:{xs:'70%',sm:'70%',md:'80%',lg:'80%'}},
  color:'white',
  borderWidth:0,
  height:{sm:50,md:50,xs:40,},
  width: {sm:450,md:450,xs:240,},

  "& ::placeholder": {
    color: "white",
    opacity: 0.8,
    fontSize:{xs:10}
  },

};
const Login = () => {
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [showPassword,setShowPassword]=useState(false)

  const dispatch = useDispatch()

const loading = useSelector(state=>state.auth.loading)
const errorss = useSelector(state=>state.auth.data?.message)

  return (
    <Container>

   
    <Container dir="rtl" sx={{display:'flex',justifyContent:{xs:'space-around',sm:'flex-end',md:'flex-end',lg:'flex-end'},mt:{xs:10,sm:10,md:0}}}>
      <Hidden smDown>
         <Box flex={1} style={{alignSelf:'flex-end',position:'fixed',bottom:0,right:10}}>
      <img src={photo_png3} width='50.2%' />
      </Box>
      </Hidden>
     {/* <Hidden smUp>
        <Box style={{alignSelf:'flex-end'}}>
      <img src={photo_png3} width='90%' />
      </Box>
     </Hidden> */}
     
        <Box>      
        <Box marginBottom='10%'>
      <InputLabel
        color="primary"
        sx={{
          textAlign: "center",
          color: theme.palette.secondary.main,
          fontSize: 36,

        }}
      >
        تسجيل دخول
      </InputLabel>
      <InputLabel
        sx={{ color: theme.palette.primary.main, textAlign: "center" }}
      >
        أهلا و سهلا بك مجددا
      </InputLabel>
      </Box>
      <Paper sx={style}>
        {/* <TextField placeholder="الاسم الاول" sx={style1}></TextField> */}
        <Input
        onChange={(e)=>setEmail(e.target.value)}
        disableUnderline
        sx={style1}
          id="input-with-icon-adornment"
          placeholder='البريد الالكتروني / الجوال '
          startAdornment={
            <InputAdornment position="start" sx={{paddingLeft:2}}>
              <AccountCircle sx={{color:'white',backgroundColor:'rgba(242, 242, 242, 0.6)',borderRadius:50,padding:{xs:0.5}}}/>
            </InputAdornment>
          }
        />
        
      </Paper>
      <Paper sx={style}>
        {/* <TextField placeholder="الاسم الأخير" sx={style1}></TextField> */}
          <Input
        onChange={(e)=>setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
        disableUnderline
        sx={style1}
          placeholder='كلمة المرور'
          startAdornment={
            <InputAdornment position="start" sx={{paddingLeft:2}}>
              <Lock sx={{color:'white',backgroundColor:'rgba(242, 242, 242, 0.6)',borderRadius:50,padding:{xs:0.5}}}/>
            </InputAdornment>
          }
          endAdornment={
            <IconButton onClick={()=>setShowPassword(!showPassword)}>
              { showPassword 
              ? <VisibilityOff sx={{color:'white'}}/>
              : <Visibility sx={{color:'white'}}/>
              }
              
            </IconButton>
          }
        />
      </Paper>
      <Box display="flex" justifyContent="space-around" ml={{xs:3}}>
        <FormGroup>
          <FormControlLabel control={<Checkbox />} label="تذكر" sx={{color:theme.palette.primary.main,'& 	.MuiFormLabel-root':{fontSize:{xs:10}}}}/>
        </FormGroup>
        <Link component={brLink} to='/forget-password' style={{ alignSelf: "center"}} sx={{fontSize:{xs:12}}}>هل نسيت كلمة المرور؟</Link>
      </Box>
      <Box width='100%' display='flex' justifyContent='space-evenly' marginTop='2%' columnGap={1}> 
      {
        loading ? <CircularProgress />
        :
        <_Button onClick={()=>dispatch(signin({email,password}))} height={40} width={`200`}>تسجيل الدخول</_Button> 

      }
        <Link component={brLink} to='/register' style={{ alignSelf: "center"}}  sx={{fontSize:{xs:12}}}>ليس لديك حساب جديد؟ انقر هنا</Link>
     
      </Box>
      {
        <FormLabel sx={{color:'red'}}>{errorss}</FormLabel>
      }
      </Box>
    </Container>
    <Hidden smUp>
        <Box style={{alignSelf:'flex-end',position:'relative',right:0,bottom:-20}}>
      <img src={photo_png3} width='90%' />
      </Box>
     </Hidden>
     
    </Container>
  );
};

export default Login;
