
import { Box, Hidden } from "@mui/material";
import React from "react";
import Logo_1 from "../../assets/images/Logo_1.png";
import img1 from "../../assets/images/img_1 (copy).png";
import img2 from "../../assets/images/img_2 (copy).png";
import img3 from "../../assets/images/photo png6.png";
import { theme } from "../../theme";
export default function VisitorFooter1() {
  return (
   
      <div
        dir="rtl"
      >
          <Hidden smDown mdDown>
          <img src={img1} width={635} style={{position:'absolute', right:0}}/>
        </Hidden>
        <Hidden mdUp>
        <img src={img1} width={250} style={{position:'absolute', right:0}}/>
        </Hidden>

        {/* <Hidden xsDown smDown>
        <img src={img2} width="50%" style={{position:'absolute', left:200,zIndex:-1,marginTop:'-150px'}}/>
        </Hidden> */}
        {/* <img
          src={img3}
          width="50%"
          style={{position:'relative',right:'40%', zIndex: 10,bottom:'-9px',transform: "scaleX(-1)"}}
        /> */}

        {/* <img src={img1} width="45%" style={{position:'absolute', left:0, transform: "scaleX(-1)" }} /> */}
     <Hidden xsDown smDown>
     <img src={img2} width={635} style={{position:'absolute',right:130,marginTop:-70 ,transform:'rotate(-30deg)'}}/>

     </Hidden>

      </div>
  );
}

