import { Box, Container, Hidden, InputLabel, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import _Button from "../../components/Button";
import { theme } from "../../theme";
import img3 from "../../assets/images/HomePage_1.png";
import { useSelector } from "react-redux";
import Logo_1 from '../../assets/images/Logo_1.png'
const Home = (props) => {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
        justifyContent: {
          xs: "space-arround",
          sm: "space-arround",
          md: "center",
          lg: "center",
        },
        flex: 1,
        height: "60vh",
      }}
    >
      <Box display='flex' flexDirection="column" rowGap={10} >
        <Hidden smDown mdDown>
          <img src={Logo_1} width={'70%'} />
        </Hidden>
        <Hidden mdUp>
          <img src={Logo_1} width={'70%'} style={{alignSelf:'center'}}/>
        </Hidden>
      <Box
      flexDirection={{xs:'column',sm:'column',lg:'row',md:'row'}}
      rowGap={1}
        display="flex"
        alignSelf={{
          xs: "center",
          sm: "center",
          md: "flex-end",
          lg: "flex-end",
        }}
        justifySelf="flex-start"
        justifyContent="flex-start"
        alignItems={"center"}
        flex={1}
        width="100%"
        columnGap={2}
        mr={{xs:0,sm:0,md:-5,lg:-5}}
        mt={{xs:10,sm:10,md:0,lg:0}}
        position={{xs:'absolute',sm:'absolute',md:'static',lg:'static'}}
        top={'60%'}
        zIndex={2}
      >
         
        <_Button
          fontSize={{xs: 11, sm: 11, md: 16, lg: 16}}
          width={{ xs: 130, sm: 130, md: 200, lg: 200 }}
          backgroundColor={theme.palette.secondary.main}
          onClick={() => navigate("/login")}
          height={{xs: 40, sm: 40, md: 60, lg: 60}}
        >
          تسجيل دخول
        </_Button>
        <_Button
          height={{xs: 40, sm: 40, md: 60, lg: 60}}
          width={{ xs: 130, sm: 130, md: 200, lg: 200 }}
          backgroundColor={theme.palette.primary.main}
          onClick={() => navigate("/register")}
          fontSize={{xs: 11, sm: 11, md: 16, lg: 16}}
        >
          تسجيل حساب جديد  
        </_Button>
      </Box>
           
      </Box>
      <Box alignSelf="flex-end" flex={1}>
        <Hidden smDown>
          <img
            src={img3}
            style={{
              alignSelf: "flex-end",
              position: "fixed",
              bottom: 0,
              right: "25%",
              width: "22%",
            }}
          />
        </Hidden>
        <Hidden smUp>
          <img
            src={img3}
            style={{
              alignSelf: "flex-end",
              position: "fixed",
              bottom: 0,
              right: "3%",
              width: '77%',
            }}
          />
        </Hidden>
        <Hidden xsUp>
          <img
            src={img3}
            style={{
              alignSelf: "flex-end",
              position: "fixed",
              bottom: 0,
              right: "2%",
              width: "120%",
            }}
          />
        </Hidden>
      </Box>
    </Container>
  );
};

export default Home;
