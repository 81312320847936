import { Button } from "@mui/material";
import React from "react";
import { theme } from "../theme";

const _Button = ({
  children,
  backgroundColor,
  style,
  onClick,
  height,
  width,
  type,
  fontSize,
  disabled
}) => {
  return (
    <Button
    disabled={disabled || false}
      type={type}
      onClick={onClick}
      style={style}
      variant="contained"
      sx={{
        backgroundColor: theme.palette.primary.main,
        ":hover":{backgroundColor:theme.palette.action.hover},
        borderRadius: "40px",
        height: height,
        width: width,
        fontSize: fontSize,
      }}
    >
      {children}
    </Button>
  );
};

export default _Button;
