import { Box, FormLabel, InputLabel } from "@mui/material";
import React from "react";
// import Logo_1 from '../../../../assets/images/photo_png1.png'
import text_1 from "../../../../demo/text";
import { theme } from "../../../../theme";

const Item_3 = ({ title, img,img1, txt3, mb, txt, txt2, ms, as,nb }) => {
  return (
    <Box
      sx={{
        alignSelf: { xs: "", sm: "", md: as, lg: as },
        width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
        mt: mb ? 1 : ms && 2,
        gap:0.5,
        display:'flex',flexDirection:'column'
      }}
    >
      <InputLabel sx={{color:theme.palette.secondary.main,textAlign:'center',fontSize:20}} >{txt}</InputLabel>
      <InputLabel sx={{color:theme.palette.secondary.main,textAlign:'center',fontSize:20}}>{txt2}</InputLabel>
      <Box display='flex' justifyContent='center' alignItems='center' gap={1}>
      <InputLabel sx={{backgroundColor:theme.palette.secondary.main,borderRadius:20,color:'white',padding:nb==2 ? '7px 18px' : '7px 14px',paddingTop:1.7,verticalAlign:'center'}}>{nb}</InputLabel>
        <img src={img} width='15%' height='auto'/>
      </Box>
      <InputLabel sx={{color:theme.palette.secondary.main,textAlign:'center',fontSize:20}}>{txt3}</InputLabel>
    </Box>
  );
};

export default Item_3;
