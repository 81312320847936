import { Box, FormLabel, Hidden } from "@mui/material";
import React from "react";
import Img333 from '../../../../assets/images/PNG-01.png'
import text_1 from "../../../../demo/text";
import { theme } from "../../../../theme";

const Item_2 = ({ title, img, mb, txt, ms, as }) => {
  return (
    // <Box
    //   sx={{
    //    flex:1
    //   }}
    // >
    <>
 <Hidden smDown mdDown>
          <img src={img} width='40%' height='auto'/>
    </Hidden>

    <Hidden mdUp>
          <img src={img} width='100%' height='auto'/>
    </Hidden>

    </>
    //  </Box>
  );
};

export default Item_2;
