import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../util/API";

const initialState = {
  data: [],
  loading: false,
  error: false,
};

export const getClasses = createAsyncThunk("classesSlice/get", async (id) => {
  try {
    let res = await API.get("getclassesbyschoolid/" + id);
    if (res.data.success) {
      return res.data.data;
    }
  } catch (err) {
    if (!err.response) {

    }
  }
});

export const classesSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: {
    [getClasses.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getClasses.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getClasses.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {} = classesSlice.actions;

export default classesSlice.reducer;
