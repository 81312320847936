import { Box, Container, Divider, FormLabel, Hidden, InputLabel } from '@mui/material'
import React from 'react'
import img3 from "../../../assets/images/img0.png";
import VisitorFooter1 from "../../../layout/footer/VisitorFooter1";
import text_1 from "../../../demo/text";
import { theme } from '../../../theme';
import Item_1 from './components/Item_1';
import Item_0 from './components/Item_0';
import Img111 from '../../../assets/images/PNG-10.png'
import Img222 from '../../../assets/images/PNG-09.png'
import Img333 from '../../../assets/images/PNG-01.png'

import Img11 from '../../../assets/images/PNG-11.png'
import Img12 from '../../../assets/images/PNG-12.png'
import Img14 from '../../../assets/images/PNG-14.png'
import Img15 from '../../../assets/images/PNG-15.png'
import Img16 from '../../../assets/images/PNG-16.png'

import Img7 from '../../../assets/images/PNG-07.png'
import Img8 from '../../../assets/images/PNG-08.png'
import Img8Mob from '../../../assets/images/PNG-03-47.png'

import Img7Mob from '../../../assets/images/PNG-03-46.png'
import Img100 from '../../../assets/images/PNG-100.png'

import Img4 from '../../../assets/images/PNG-04.png'
import Img5 from '../../../assets/images/PNG-05.png'
import Img6 from '../../../assets/images/PNG-06.png'
import Img101 from '../../../assets/images/PNG-101.png'
import Img02 from '../../../assets/images/PNG-02.png'




import Item_2 from './components/Item_2';
import Item_3 from './components/Item_3';
import Item_4 from './components/Item_4';
import Item_5 from './components/Item_5';
export const About_project = () => {
    return (
        <Container>

                  {/* header 1 */}
          <Box dir="rtl" textAlign='center'>
            <FormLabel
              dir="rtl"
              sx={{
                fontSize: {xs:32,sm:32,md:48,lg:48},
                color: theme.palette.primary.main,
                textAlign: "center",
              }}
            >
              تعريف وأهداف برنامج تكوين            </FormLabel>
              <Box>
              <FormLabel dir="rtl" sx={{color:theme.palette.secondary.main}}>{`برنامج تكوين هو إحدى مبادرات أكاديمية مهد الرياضية المستهدفة الأطفال ما بين عمر 6-9 سنوات، ويهدف البرنامج إلى تنمية المهارات الرياضية والحركية اللازمة لدى الأطفال في عدد من الرياضات مثل كرة القدم والألعاب القتالية من خلال عقد حصص تدريبية منظمة على أيدي مدربين محترفين`}</FormLabel>

              </Box>
          </Box>
            {/* header 1 */}


            {/* iten 1 */}
            <Container sx={{mt:10}}>

            <Item_1 txt={`أهداف البرنامج`}/>

            <Container sx={{justifyContent:'space-between',display:'flex',marginTop:2,
            flexDirection:{xs:'column',sm:'column',md:'column',lg:'row'}
            }}>
                
                      <Item_0 as='flex-start' title={'هدفنا'} img={Img333} txt={`توسيع قاعدة المدربين العاملين مع الأعمار الصغيرة لصقل مهاراتهم التدريبية واكتساب الخبرات اللازمة`}/>
                      <Divider orientation='vertical' sx={{mt:{xs:5,sm:5,md:0,lg:0},mb:{xs:5,sm:5,md:0,lg:0},mr:5,ml:5,borderWidth:1,borderColor:theme.palette.secondary.main}} flexItem/>
                      <Item_0 mb={true} as='center' title={'قيمنا'} img={Img222} txt={` توفير آلية للتعرف على المواهب المحتملة ذوي الإمكانات الرياضية المستقبلية في جميع أنحاء المملكة، وترشيحهم لأكاديمية مهد في المستقبل`}/>
                      <Divider orientation='vertical' light sx={{mt:{xs:5,sm:5,md:0,lg:0},mb:{xs:5,sm:5,md:0,lg:0},mr:5,ml:5,borderWidth:1,borderColor:theme.palette.secondary.main}} flexItem/>
                      <Item_0 ms={true} as='flex-start' title={'رؤيتنا'} img={Img111}  txt={`تعزيز ممارسة النشاط البدني والمشاركة الرياضية في الأعمار الصغيرة`}/>                    
                      <Divider orientation='vertical' sx={{mt:{xs:5,sm:5,md:0,lg:0},mb:{xs:5,sm:5,md:0,lg:0},mr:5,ml:5,borderWidth:1,borderColor:theme.palette.secondary.main}} flexItem/>
           
              </Container>

            </Container>
            {/* iten 1 */}


               {/* iten 2*/}
               <Container sx={{mt:10}}>

<Item_1 txt={`سيتم بدء برنامج تكوين في خمس مدارس`} txt1={`على مستوى مدينة الرياض كمرحلة أولى `}/>

<Container sx={{justifyContent:'center',display:'flex',marginTop:2,width:'100%',flexWrap:'wrap',
flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},gap:2,columnGap:20
}}>
          <Item_2 ms={true} as='center' title={'رؤيتنا'} img={Img11}  />  
          <Item_2 ms={true} as='center' title={'رؤيتنا'} img={Img12}  />   
          <Item_2 mb={true} as='center' title={'قيمنا'} img={Img15}/>
          <Item_2 ms={true} as='center' title={'رؤيتنا'} img={Img14}  />                    
          <Item_2 as='flex-start' title={'هدفنا'} img={Img16} txt={`توسيع قاعدة المدربين العاملين مع الأعمار الصغيرة لصقل مهاراتهم التدريبية واكتساب الخبرات اللازمة`}/>


  </Container>

</Container>
{/* iten 2*/}

   {/* iten 3*/}
   <Container sx={{mt:10}}>

<Item_1 txt={`رحلة المستخدم`} txt1={`– من التسجيل إلى حضور الحصة التدريبية الأولى`} eo={true}/>

<Container sx={{justifyContent:'center',display:'flex',marginTop:2,width:'100%',flexWrap:'wrap',
flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},gap:2,columnGap:20
}}>
        <Hidden smDown mdDown>
      <img src={Img7} width='100%' height={'auto'}/>

      </Hidden>
      <Hidden mdUp>
      <img src={Img7Mob} width='100%' height={'auto'}/>

      </Hidden>




  </Container>

</Container>
{/* iten 3*/}

  {/* iten 4*/}
  <Container sx={{mt:10}}>

<Item_1 txt={`رحلة المستخدم`} txt1={`– حضور الحصص التدريبية`} eo={true}/>

<Container sx={{justifyContent:'center',display:'flex',marginTop:2,width:'100%',flexWrap:'wrap',
flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},gap:2,columnGap:20
}}>
         <Hidden smDown mdDown>
         <img src={Img8} width='100%' height={'auto'}/>

      </Hidden>
      <Hidden mdUp>
      <img src={Img8Mob} width='100%' height={'auto'}/>

      </Hidden>




  </Container>

</Container>
{/* iten 4*/}

  {/* iten 5*/}
  <Container sx={{mt:10}}>

<Item_1 txt={`عناصر تفعيل برنامج تكوين`} txt1={`... وتم تصميم برنامج تدريبي متوازن مما سيسهم في تحقيق أعلى استفادة للأطفال في هذه المرحلة السنية`}/>

<Container sx={{justifyContent:'center',display:'flex',marginTop:2,width:'100%',flexWrap:'wrap',
flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},gap:2,columnGap:20
}}>
      
<img src={Img100} width='90%' height={'auto'}/>


  </Container>
  <Container sx={{justifyContent:'center',display:'flex',marginTop:5,width:'100%',
flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},
}}>
      
<Item_3 txt={`عدد الرياضات المسموح`} txt2={`للطالب بالاشتراك فيها`} txt3={` بحد أقصى`} img={Img6} nb={2}/>
<Divider orientation='vertical'  sx={{mt:{xs:5,sm:5,md:0,lg:0},mb:{xs:5,sm:5,md:0,lg:0},mr:5,ml:5,borderWidth:1,borderColor:theme.palette.secondary.main}} flexItem/>
<Item_3 txt={`عدد الطلاب في كل`} txt2={`مجموعة`} txt3={`طالب بحد أقصى`} img={Img5} nb={30}/>
<Divider orientation='vertical' sx={{mt:{xs:5,sm:5,md:0,lg:0},mb:{xs:5,sm:5,md:0,lg:0},mr:5,ml:5,borderWidth:1,borderColor:theme.palette.secondary.main}} flexItem/>
<Item_3 txt={`مدة كل حصة`} txt2={`تدريبية`} txt3={`دقيقة`} img={Img4} nb={60}/>

  </Container>
  <Container sx={{justifyContent:'center',display:'flex',marginTop:2,width:'100%',flexWrap:'wrap',
flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'},gap:2,columnGap:20
}}>
      
<img src={Img101} width='100%' height={'auto'}/>


  </Container>
</Container>
{/* iten 5*/}

   {/* iten 6*/}
   <Container sx={{mt:10}}>
<Item_1 txt={`نطاق العمل (تفعيل البرنامج 15 مدرسة)`} txt1={`يهدف البرنامج الى  تفعيله في 15 مدرسة من مدارس الحي على مستوى المملكة، وبالتعاون مع وزارة التعليم واتحاد الرياضات المدرسية `}/>

<Container sx={{justifyContent:'center',display:'flex',marginTop:2,width:'100%',
flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'}
}}>
          <Item_5 ms={true} as='center' txt={'المجموع'} txt2={'رؤيتنا'} txt3={'رؤيتنا'} img={Img12}  />    
          <Item_4 ms={true} as='center' txt={'المنطقة'} txt2={'الوسطى'} txt3={'الغربية'} img={Img12}  />
          <Item_4 ms={true} as='center' txt={'المدينة'} txt2={'الرياض'} txt3={'جدة'} img={Img11}  />  
          <Item_4 ms={true} as='center' txt={'العدد'} txt2={'10'} txt3={'5'} img={Img12}  />   


  </Container>

</Container>
{/* iten 6*/}
     {/* footer */}
     <Container sx={{marginTop:10}}>
      <VisitorFooter1 /> 
      <Container dir="rtl" alignSelf="flex-end">
        <img
          src={img3}
          width="55%"
          height='100%'
          style={{ alignSelf: "flex-end", zIndex: 2, position: "relative" }}
        />
         <img
          src={Img02}
          width="40%"
          height='auto'
          style={{ alignSelf: "flex-end", zIndex: 2, position: "relative",right:'10%',top:-60}}
        />
      </Container>
      </Container>

            {/* footer */}
        </Container>
    )
}
