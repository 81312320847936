import { Box, InputLabel } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { theme } from '../theme';

const HeaderInfo = () => {
    const firstName = useSelector((state) => state.auth?.data?.user?.first_name);
    const accessToken = useSelector((state) => state.auth?.data?.token);
    return (
        <Box dir='rtl' style={{display:'flex',justifyContent:'space-between',marginTop:-15,marginRight:40}}>
            {
                accessToken ?
            <InputLabel sx={{color:theme.palette.secondary.main,fontSize:24}}>مرحبا {firstName}</InputLabel>
                :
                null
            }
            <Box>

            </Box>
        </Box>
    )
}

export default HeaderInfo
