import { Box } from '@mui/material'
import React from 'react'
import RegisterForm from './components/RegisterForm'
import Right_img from '../../assets/images/img_1 (copy).png'
import VisitorFooter from '../../layout/footer/VisitorFooter'
const Register = () => {
  return (
    <>
      <RegisterForm/>
      <VisitorFooter />
    </>
  )
}

export default Register
