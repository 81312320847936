import React, { useState } from 'react'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { theme } from '../../theme';
import { FormLabel, InputLabel } from '@mui/material';
import OtpInput from 'react-otp-input';
import _Button from '../../components/Button';
import { useNavigate } from 'react-router';
const OTP = () => {
    const [otp,setotp]=useState()
    const navigate = useNavigate()
    return (
        <div style={{textAlign:'center'}}>
            <ForwardToInboxIcon sx={{color:theme.palette.primary.main,fontSize:{xs:50,sm:50,md:100,lg:100},textAlign:'center'}}/>
            <InputLabel sx={{color:theme.palette.primary.main,fontSize:{xs:14,sm:14,md:28,lg:28}}}>لقد ارسلنا رمز وصول عبر الرسائل القصيرة </InputLabel>
            <InputLabel sx={{color:theme.palette.primary.main,fontSize:{xs:14,sm:14,md:28,lg:28}}}>للتحقق من رقم الهتتف المحمول</InputLabel>
            <InputLabel sx={{color:theme.palette.secondary.main,fontSize:{xs:8,sm:8,md:16,lg:16}}}>ادخل الرمز</InputLabel>
            <div style={{width:'100%',justifyContent:'center'}}>

            
            <OtpInput
        value={otp}
        onChange={setotp}
        numInputs={5}
        separator={<span>{' '}</span>}
        containerStyle={{justifyContent:'center'}}
        inputStyle={{width:40,borderRadius:40,height:40,margin:5,fontSize:24,borderColor:theme.palette.primary.main,color:theme.palette.secondary.main}}
        style={{justifyContent:'center'}}
      />
      </div>
      <_Button style={{padding:20,width:200}} onClick={()=>navigate('/')}>ارسال</_Button>
        </div>
    )
}

export default OTP
