import { InputLabel, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import img_2 from '../assets/images/Canvas.jpg'
import { theme } from '../theme'
const useStyle=makeStyles({
    img:{
        width:'100%',
        height:'100%',
        borderRadius:100,
        backgroundPosition:'center',
        objectFit:'cover'
        
    },
    title:{
        textAlign:'center',
        fontSize:'24px',
        color:theme.palette.secondary.main
    }
})
const News = (props) => {
    const style=useStyle();
    return (
        <Box dir='rtl'>
        <Box width={100} height={100}>
            <img src={img_2} className={style.img}/>
        </Box>
        <Box>
            <InputLabel size={24} sx={{fontSize:'24px',color:theme.palette.secondary.main}} className={style.title}>hello</InputLabel>
        </Box>
        <Box>
            <InputLabel sx={{fontSize:'20px',color:theme.palette.primary.main,textAlign:'center'}} >hello</InputLabel>
        </Box>
        <Box>
            <InputLabel sx={{textAlign:'center'}}>2020</InputLabel>
        </Box>
        </Box>
    )
}

export default News
