import { ArrowBack, ArrowBackIos, ArrowForwardIos, Forward } from '@mui/icons-material'
import React, { useState } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import style from '../App.module.css'
import { theme } from '../theme'

const SideBar = () => {
    const [show,setshow]=useState(true)
    let isActiveStyle={
        color:theme.palette.secondary.main,
        textDecoration:'none',
        padding:3,
        margin:10,
        fontSize:20,
        marginRight:-10,
        borderRight:'3px solid white'
    }
    let isNotActiveStyle={
        textDecoration:'none',
        color:'white',
        marginRight:50

    }
    return (
        <>
           <div className={show ? style.SideBar_container : style.SideBar_container_hide}>
               <ul>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle} to='/dashboard/cities'>
                       ادارة المدن
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/nationalities'>
                       ادارة الجنسيات
                   </NavLink>
                   </li> 
                    <li>
                    <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/sports'>
                       ادارة الرياضة
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/schools'>
                       ادارة المدارس
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/questions'>
                       ادارة الاسئلة و الاجوبة
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/a'>
                       ادارة الصفوف
                   </NavLink>
                   </li>
                  
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities5'>
                       ادارة الدورة التدربية
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities6'>
                       ادارة المدربين
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities7'>
                       ادارة الاداريين
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities8'>
                       ادارة المواهب
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities9'>
                       ادارة الحضور و الغياب
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/evaluations'>
                       ادارة التقييم
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities11'>
                       ادارة المالية
                   </NavLink>
                   </li>
               </ul>
               <div className={style.Open_button}  onClick={()=>setshow(!show)}>
                   {
                       show 
                       ?
                       <ArrowForwardIos sx={{color:'white'}}/>

                       :
                       <ArrowBackIos  sx={{color:'white'}}/>

                   }
                   
               </div>
               </div> 
               <Outlet />
             
        </>
    )
}

export default SideBar
