import React from 'react'
import { useSelector } from 'react-redux';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import VisitorLayout from '../layout/VisitorLayout'
import Login from '../Pages/Login';
import Register from '../Pages/register';
export default function RoutesNavigation() {
    const accessToken = useSelector(state=>state.auth?.data?.data?.token)
    return (
        <div>
            <Routes>
            <Route
          path="/*"
          element={
            // <RequireAuth token={accessToken}>
              <VisitorLayout />
            // </RequireAuth>
          }
        />
        {/* <Route
          path="/login"
          element={
            <UnRequireAuth token={accessToken}>
              <Login />
            </UnRequireAuth>
          }
        />
        <Route
          path="/register"
          element={
            <UnRequireAuth token={accessToken}>
              <Register />
            </UnRequireAuth>
          }
        /> */}
            </Routes>
        </div>
    )
}

function UnRequireAuth({ children, token }) {
    let location = useLocation();
    if (token) {
      return <Navigate to={`/news`} state={{ from: location }} replace />;
    } else {
      return children;
    }
  }
  function RequireAuth({ children, token }) {
    let location = useLocation();
    if (!token) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
      return children;
    }
  }
  