import { AddCircle, BorderColor, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormLabel,
  IconButton,
  InputLabel,
  Pagination,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getCities, Delete_City, Update_city } from "../../../features/citiesSlice";
import { theme } from "../../../theme";
import City from "./components/City";

const Cities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const state = useSelector((state) => state.cities);
  const [page,setPage]=useState(1)
  const handleChange = (event, value) => {
    setPage(value);
  };
  console.log(page)
  useEffect(() => {
    dispatch(getCities({paginate:1,page:page}));
  }, [dispatch,page]);
  useEffect(() => {
    setCities(state.data);
  }, [state.data]);
  const DeleteCity = async (id) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await dispatch(Delete_City(id)).unwrap();
      if (response.success) {
        setCities(cities.filter((city) => city.id != id));
      }
    }
  };
  const updateCity = async (data) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await dispatch(Update_city(data)).unwrap();
      if (response.success) {
        dispatch(getCities({paginate:1,page:page}));
      }
    }
  };
  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box dir="rtl" textAlign="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
            }}
          >
            المدن
          </InputLabel>
          <IconButton
            color="secondary"
            component="span"
            onClick={() => navigate("/dashboard/cities/new-city")}
          >
            <AddCircle
              sx={{ olor: theme.palette.secondary.main, fontSize: 32 }}
            />
          </IconButton>
        </Box>
        <Box>
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
              width: "70%",
            }}
          >
            المدن
          </InputLabel>
          {
            state.loading ? (
              <Box mb={20}>
                  <CircularProgress />
                </Box>
            ) : 
            <Box display='flex' flexDirection='column' rowGap={2}>
          {
          cities && cities?.data?.length > 0 ? (
            cities?.data?.map((city) => (
              <City
                name={city.name}
                id={city.id}
                key={city.id}
                DeleteCity={DeleteCity}
                UpdateCity={updateCity}
              />
            ))
          ) : (
            <label>no data</label>
          )}
          </Box>
          }
          
        </Box>
      </Box>
      <Box alignSelf="center" marginBottom={10} marginTop={5}>
        <Pagination count={2} onChange={handleChange} page={page}/>
      </Box>
    </Box>
  );
};

export default Cities;
