import { configureStore } from '@reduxjs/toolkit';
import  authSlice  from '../features/authSlice';
import  shcoolsSlice  from '../features/shcoolsSlice';
import counterReducer from '../features/counter/counterSlice';
import citiesSlice from '../features/citiesSlice';
import classesSlice  from '../features/classesSlice';
import scheduledSlice from '../features/scheduledSlice';
import nationalitiesSlice from '../features/nationalitiesSlice';
import sportsSlice from '../features/sportsSlice';
import schoolsSlice from '../features/schoolsSlice';
import questionsSlice from '../features/questionsSlice';
import evaluationsSlice from '../features/evaluationsSlice';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authSlice,
    shcools: shcoolsSlice,
    cities: citiesSlice,
    sports: sportsSlice,
    nationalities: nationalitiesSlice,
    classes:classesSlice,
    scheduleds:scheduledSlice,
    schoolsDetails:schoolsSlice,
    questionsSlice:questionsSlice,
    evaluationsSlice:evaluationsSlice
  },
});
