import { AddCircle, BorderColor, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormLabel,
  IconButton,
  InputLabel,
  Pagination,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getShcools } from "../../../features/schoolsSlice";
import { theme } from "../../../theme";
import School from "./components/School";


const Schools = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shcools, setSchools] = useState([]);
  const state = useSelector((state) => state.schoolsDetails);
  const [page,setPage]=useState(1)
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getShcools());
  }, [dispatch,page]);
  useEffect(() => {
    setSchools(state.data);
  }, [state.data]);
  // const DeleteCity = async (id) => {
  //   let bool = window.confirm("هل انت متأكد؟");
  //   if (bool) {
  //     const response = await dispatch(Delete_City(id)).unwrap();
  //     if (response.success) {
  //       setCities(cities.filter((city) => city.id != id));
  //     }
  //   }
  // };
  // const updateCity = async (data) => {
  //   let bool = window.confirm("هل انت متأكد؟");
  //   if (bool) {
  //     const response = await dispatch(Update_city(data)).unwrap();
  //     if (response.success) {
  //       dispatch(getCities({paginate:1,page:page}));
  //     }
  //   }
  // };
  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box dir="rtl" textAlign="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
            }}
          >
            المدارس
          </InputLabel>
          <IconButton
            color="secondary"
            component="span"
            onClick={() => navigate("/dashboard/cities/new-city")}
          >
            <AddCircle
              sx={{ olor: theme.palette.secondary.main, fontSize: 32 }}
            />
          </IconButton>
        </Box>
        <Box>
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
              width: "70%",
              marginBottom:5
            }}
          >
            المدارس
          </InputLabel>
          {
            state.loading ? (
              <Box mb={20}>
              <CircularProgress />
            </Box>
            ) : 
            <Box display='flex' flexDirection='column' rowGap={2}>
          {
          shcools && shcools?.length > 0 ? (
            shcools?.map((school) => (
              <School
              key={school.school_id}
              school_id={school.school_id}
              school_name={school.school_name}
              school_city_name={school.school_city_name}
              school_address={school.school_address}
              manager_firstname={school.manager_firstname}
              manager_lastname={school.manager_lastname}
              talents={school.talents}
              security={school.security}
              janitors={school.janitors}
              coaches={school.coaches}
              coaches_names={school.coaches_names}
              janitors_names={school.janitors_names}
              security_names={school.security_names}
              />
            ))
          ) : (
            <label>no data</label>
          )}
          </Box>
          }
          
        </Box>
      </Box>
      <Box alignSelf="center" marginBottom={10} marginTop={5}>
        <Pagination count={state.data.last_page} onChange={handleChange} page={page}/>
      </Box>
    </Box>
  );
};

export default Schools;