import {
  FacebookOutlined,
  Instagram,
  Twitter,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import { Box, Hidden } from "@mui/material";
import React from "react";
import Logo_1 from "../../assets/images/Logo_1.png";
import img1 from "../../assets/images/img_1 (copy).png";
import img2 from "../../assets/images/img_2 (copy).png";
import img3 from "../../assets/images/photo png6.png";
import { theme } from "../../theme";
export default function VisitorFooter() {
  return (
   
      <div
        dir="rtl"
      >
        <img src={img1} width="45%" style={{position:'absolute', right:0,}}/>
        <Hidden xsDown smDown>
        <img src={img2} width="50%" style={{position:'absolute', left:200,zIndex:-1,marginTop:'-150px'}}/>
        </Hidden>
        <Hidden smDown mdDown>
        <img
          src={img3}
          width="50%"
          style={{position:'relative',right:'40%', zIndex: 10,bottom:'-9px',transform: "scaleX(-1)"}}
        />
        </Hidden>
        <Hidden mdUp>
        <img
          src={img3}
          width="50%"
          style={{position:'relative',right:'40%', zIndex: 10,bottom:'-3px',transform: "scaleX(-1)"}}
        />
        </Hidden>
       
       

        <img src={img1} width="45%" style={{position:'absolute', left:0, transform: "scaleX(-1)" }} />
     <Hidden xsDown smDown>
     <img src={img2} width="50%" style={{position:'absolute',right:350,marginTop:-60 ,transform:'rotate(180deg)',zIndex:-1}}/>

     </Hidden>

      </div>
  );
}

{
  /* <Box diplay='flex' justifyContent='space-between' >
                <FacebookOutlined sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <Instagram sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <Twitter sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <WhatsApp sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <YouTube sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
            </Box> */
}
