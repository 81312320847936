import { AccountCircle } from '@mui/icons-material'
import { Box, Container, FilledInput, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import _Button from '../../../components/Button'
import { add_City } from '../../../features/citiesSlice'
import { theme } from '../../../theme'

export const NewCity = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name,setName] = useState('')
    const {add_data:{success}} = useSelector(state=>state.cities)
    const createCity=async()=>{
      dispatch(add_City({name}))
    }
    useEffect(() => {
     if(success){
      navigate('/dashboard/cities')
     }
    }, [success])
    return (
        <Box dir='rtl' style={{justifyContent:'center',display:'flex'}}>

        <Box 
        height='100px'
        style={{
            width:'50%',
            height:'300px',
            background:'white',
            borderRadius:50,
            textAlign:'center',
            alignItems:'center',
            display:'flex',
            justifyContent:'center'
        }}
        >
            <FilledInput
            value={name}
            onChange={(e)=>setName(e.target.value)}
         disableUnderline
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          height:40,
          m:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white'
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
        //  startAdornment={<InputAdornment position={'start'}><AccountCircle/></InputAdornment>}
         ></FilledInput>
             <_Button onClick={createCity}>انشاء</_Button>
            
        </Box>

        </Box>
    )
}
