import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../util/API";

const initialState = {
  data: [],
  loading: false,
  error: false,
  delete_data: {
    success: "",
    message: "",
  },
  add_data: {},
};

export const getSports = createAsyncThunk("sportsSlice/get", async (id) => {
  try {
    let res = await API.get("getsports");
    if (res.data.success) {
      return res.data.data;
    }
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    console.log(err);
  }
});

export const Delete_Sport = createAsyncThunk(
  "sportsSlice/delete",
  async (id) => {
    try {
      let res = await API.put("deletesport/" + id);
      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
    }
  }
);

export const add_Sport = createAsyncThunk("sportsSlice/add", async (name) => {
  try {
    let res = await API.post("addsport",name,{headers:{Authorization:"Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw"}});
    if (res.data.success) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    console.log(err);
  }
});

export const Update_Sport = createAsyncThunk(
  "sportsSlice/update",
  async (data) => {
    console.log({ data });
    try {
      let res = await API.post(
        "editsport/" + data.id,
        { name: data.name },
        {
          headers: {
            Authorization: "Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw",
          },
        }
      );
      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
    }
  }
);

export const sportsSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: {
    [getSports.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getSports.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getSports.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [Delete_Sport.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [Delete_Sport.fulfilled]: (state, action) => {
      state.delete_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [Delete_Sport.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [Update_Sport.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [Update_Sport.fulfilled]: (state, action) => {
      state.update_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [Update_Sport.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [add_Sport.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [add_Sport.fulfilled]: (state, action) => {
      state.add_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [add_Sport.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {} = sportsSlice.actions;

export default sportsSlice.reducer;
