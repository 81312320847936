
import { Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import _Button from '../../../components/Button'
import API from '../../../util/API';
import { Phase1 } from './components/Phase1';
import { Phase2 } from './components/Phase2';

export const NewQuestion = () => {
  const navigate = useNavigate()
  const [phase,setPhase] = useState('1')
  //question
  const [question,setQuestion] = useState('')
  const [question_degree,setQuestion_degree] = useState('')
  const [is_multiple_answers,setIs_multiple_answers] = useState('single')
  const [loading,setloading]=useState(false)
  //answer
  const [answers,setAnswers] = useState([])

  const createQuestionWithAnswers =async()=> {
    let data = {
      question,question_degree,is_multiple_answers,
      answers
    }
    try{
      setloading(true)
        let res = await API.post('addquestion',
        data,
        {headers:{Authorization: 'Bearer 66|Akro5yZ3Lqs19RNYFCPBuZ4h3WCRFSkAG0GQbJkB'}})
        if(res.data.success){
          setloading(false)
          navigate('/dashboard/questions')
        }
    }catch(e){
        setloading(false)
    }
  }
  console.log({answers})
  return(
            <Box dir='rtl' style={{justifyContent:'center',display:'flex',paddingBottom:200}}>
    <Box 
    style={{
        width:'50%',
        height:'350px',
        background:'white',
        borderRadius:50,

    }}>
    {
      phase == '1' ?
         <Phase1 setPhase={setPhase}
    setQuestion={setQuestion} 
    setQuestion_degree={setQuestion_degree}
    setIs_multiple_answers={setIs_multiple_answers}
    createQuestionWithAnswers={createQuestionWithAnswers}
    is_multiple_answers={is_multiple_answers}
    question_degree={question_degree}
    question={question}
    />
    :
    <Phase2 setPhase={setPhase} setAnswers={setAnswers} answers={answers}/>
    }
     {
        phase == '1' ? null :
        loading ? <Box display='flex' justifyContent='center'>
          <CircularProgress />
          </Box>
          :
            <Box display='flex' justifyContent='center' gap={2}>
       <_Button width={100} onClick={()=>setPhase('1')}>رجوع</_Button>
          <_Button width={100} onClick={()=>createQuestionWithAnswers()}>انشاء</_Button>
    </Box>
      }
        


    </Box>
    </Box>
  )
  if(phase == '1'){
    return (
   <>

   </>
  );
  }else{
    return (
      <>
      
      </>
     );
  }
    
};



