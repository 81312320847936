import { AddCircle, BorderColor, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormLabel,
  IconButton,
  InputLabel,
  Pagination,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Delete_Sport, getSports, Update_Sport } from "../../../features/sportsSlice";
import { theme } from "../../../theme";
import Sport from "./components/Sport";

const Sports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sports, setSports] = useState([]);
  const state = useSelector((state) => state.sports);
  useEffect(() => {
    dispatch(getSports());
  }, [dispatch]);
  useEffect(() => {
    setSports(state.data);
  }, [state.data]);
  const DeleteSport = async (id) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await dispatch(Delete_Sport(id)).unwrap();
      if (response.success) {
        setSports(sports.filter((sport) => sport.id != id));
      }
    }
  };
  const UpdateSport = async (data) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await dispatch(Update_Sport(data)).unwrap();
      if (response.success) {
        dispatch(getSports());
      }
    }
  };
  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box dir="rtl" textAlign="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
            }}
          >
            الرياضة
          </InputLabel>
          <IconButton
            color="secondary"
            component="span"
            onClick={() => navigate("/dashboard/sports/new-sport")}
          >
            <AddCircle
              sx={{ olor: theme.palette.secondary.main, fontSize: 32 }}
            />
          </IconButton>
        </Box>
        <Box>
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
              width: "70%",
            }}
          >
            الرياضة
          </InputLabel>
          {
            state.loading ? (
              <Box mb={20}>
              <CircularProgress />
            </Box>
            ) : 
            <Box display='flex' flexDirection='column' rowGap={2}>
          {
          sports && sports?.length > 0 ? (
            sports.map((sport) => (
              <Sport
                name={sport.name}
                id={sport.id}
                key={sport.id}
                DeleteSport={DeleteSport}
                UpdateSport={UpdateSport}
              />
            ))
          ) : (
            <label>no data</label>
          )}
          </Box>
          }
          
        </Box>
      </Box>
      <Box alignSelf="center" marginBottom={10} marginTop={5}>
        <Pagination count={10} />
      </Box>
    </Box>
  );
};

export default Sports;
