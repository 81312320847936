import { ArrowBack, ArrowBackIos, ArrowForwardIos, Forward } from '@mui/icons-material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import style from '../App.module.css'
import { theme } from '../theme'

const SideBarTrainer = () => {
    const [show,setshow]=useState(true)
    let isActiveStyle={
        color:theme.palette.secondary.main,
        textDecoration:'none'
    }
    let isNotActiveStyle={
        textDecoration:'none',
        color:'white'
    }
    return (
        <>
           <div className={show ? style.SideBar_container : style.SideBar_container_hide}>
               <ul>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle} to='/dashboard/cities-management'>
                       بيانات المدرب
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities1'>
                       البرنامج
                   </NavLink>
                   </li> 
                    <li>
                    <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities2'>
                       ادارة الحصص
                   </NavLink>
                   </li>               
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities10'>
                       ادارة التقييم
                   </NavLink>
                   </li>
                   <li>
                   <NavLink style={({isActive})=>isActive ? isActiveStyle:isNotActiveStyle}  to='/dashboard/cities11'>
                       ادارة المالية
                   </NavLink>
                   </li>
               </ul>
               <div className={style.Open_button_trainer}  onClick={()=>setshow(!show)}>
                   {
                       show 
                       ?
                       <ArrowForwardIos sx={{color:'white'}}/>

                       :
                       <ArrowBackIos  sx={{color:'white'}}/>

                   }
                   
               </div>
               </div> 
             
        </>
    )
}

export default SideBarTrainer
