import { Box, Divider, FormLabel } from "@mui/material";
import React from "react";
import { theme } from "../../../../theme";

const Item_1 = ({txt,txt1,eo}) => {
  return (
    <div>
      <Divider
        sx={{
          position: "absolute",
          right: 0,
          borderWidth: 20,
          borderColor: theme.palette.primary.main,
          width: {xs:100,sm:100,md:150,lg:150},
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30
        }}
      />

<Box dir='rtl' sx={{marginRight:{xs:20,sm:17,md:15,lg:6}}}>
                <FormLabel sx={{color:theme.palette.primary.main,fontSize:37}}>
                    {txt}
                </FormLabel>
                {
                  eo ?
                  <FormLabel sx={{color:theme.palette.secondary.main,fontSize:27}}>
                    {' '}{txt1}
                </FormLabel>
                :null
                }
            </Box>
            {
              eo ? null :
                <Box dir='rtl' sx={{marginRight:6,}}>
                <FormLabel sx={{color:theme.palette.secondary.main,fontSize:27}}>
                    {txt1}
                </FormLabel>
            </Box>
            }
          
    </div>
  );
};

export default Item_1;
