import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../util/API";

const initialState = {
  data: [],
  loading: false,
  error: false,
  delete_data: {
    success: "",
    message: "",
  },
  add_data: {},
};

export const getEvaluations = createAsyncThunk("evaluationsSlice/get", async (data) => {
  try {
    let res = await API.get("getevaluations",{params:data});
    if (res.data.success) {
      return res.data.data;
    }
  } catch (err) {
    if (!err.response) {
      throw err;
    }
  }
});

export const Delete_Evaluation = createAsyncThunk(
  "evaluationsSlice/delete",
  async (id) => {
    try {
      let res = await API.get("deleteevaluation/" + id);
      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
    }
  }
);

export const add_Evaluation = createAsyncThunk("evaluationsSlice/add", async (name) => {
  console.log({name})
  try {
    let res = await API.post("addevaluation",name,{headers:{Authorization:"Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw"}});
  console.log({res})
    if (res.data.success) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    console.log(err);
  }
});

export const Update_Evaluations = createAsyncThunk(
  "evaluationsSlice/update",
  async (data) => {
    console.log({ data });
    try {
      let res = await API.post(
        "editevaluation/" + data.id,
        {
          evaluation:data.evaluation,
          start_date:data.start_date,
          end_date:data.end_date
        },
      );
      console.log({res})
      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
    }
  }
);

export const evaluationsSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: {
    [getEvaluations.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.add_data.success=false;
    },
    [getEvaluations.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getEvaluations.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [Delete_Evaluation.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [Delete_Evaluation.fulfilled]: (state, action) => {
      state.delete_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [Delete_Evaluation.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [Update_Evaluations.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [Update_Evaluations.fulfilled]: (state, action) => {
      state.update_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [Update_Evaluations.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [add_Evaluation.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [add_Evaluation.fulfilled]: (state, action) => {
      state.add_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [add_Evaluation.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {} = evaluationsSlice.actions;

export default evaluationsSlice.reducer;
