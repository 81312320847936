import { BorderColor, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import _Button from "../../../../components/Button";
import { theme } from "../../../../theme";
import API from "../../../../util/API";
import icon35 from '../../../../assets/images/PNG-35.png'
const Question = ({
  id,
  name,
  degree,
  is_multiple_answers,
  answers,
  index,
  DeleteQuestion,
  updateQuestion
}) => {
  ///editing 
  const [edit_name,setEdit_name]=useState(name)
  const [edit_degree,setEdit_degree]=useState(degree)
  const [edit_is_multiple_answers,setis_multiple_answers]=useState(is_multiple_answers)

  ///
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  // const [title_talents,setTitle_talents]=useState('')
  // const [data_talents,setData_talents]=useState([])
  const [page, setpage] = useState(1);
  const [count, setCount] = useState(0);
  const handleChange = (event, value) => {
    setpage(value);
    getpaginatetalents(value);
  };

  const [loading, setloading] = useState(false);

  const setOpenDialogue = (title, data) => {
    setTitle(title);
    setData(data);
    setOpen(true);
  };

  const gettalents_by_skl_id = async () => {
    setTitle("الأجوبة");
    setOpen(true);
    setloading(true);
    try {
      let res = await API.get("answersbyquetsionid/" + id);
      console.log(res)
      if (res.data.success) {
        console.log(res)
        setData(res.data.data);
        setCount(res.data.data.last_page);
        setloading(false);
      }
    } catch (e) {
      setloading(false);
      console.log(e)
    }
  };

  const getpaginatetalents = async (value) => {
    try {
      let res = await API.get(
        `answersbyquestionid/${id}?page=${value}`
      );
      if (res.data.success) {
        console.log(res.data.data);
        setData(res.data.data);
        setloading(false);
      }
    } catch (e) {
      setloading(false);
    }
  };
  return (
    <Box display="flex" justifyContent="center" columnGap={editMode ? 10 : 1} alignSelf={'flex-end'} width='80%'>
      <Dialog disableEscapeKeyDown open={open}>
        <DialogTitle style={{ textAlign: "center" }}>{title}</DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column", margin:50 }}>
          {loading ? (
            <CircularProgress style={{ alignSelf: "center" }} />
          ) : (
            data.length &&
            data.map((d) => (
              <FormLabel style={{ textAlign: "center" }}>
                {d.name}
              </FormLabel>
            ))
          )}
          {title == "المواهب" ? (
            <Pagination
              style={{ alignSelf: "center" }}
              siblingCount={0}
              page={page}
              onChange={handleChange}
              count={count}
            />
          ) : null}
        </DialogContent>
        <DialogActions dir="rtl">
          <_Button onClick={() => setOpen(false)}>أغلق</_Button>
        </DialogActions>
      </Dialog>
      {editMode ? (
            <Box display='flex' flexDirection='column' >
            <FormLabel
              sx={{
                fontSize: 28,
                pb:2,
                color: theme.palette.secondary.main,
                mt: 1,
                display:index != 0 ? 'none' : null
              }}
            >
              السؤال
            </FormLabel>
        <FilledInput
          disableUnderline
          sx={{
            backgroundColor: "transparent",
            textAlign: "center",
            fontSize: 20,
            width: 150,
            borderWidth: 2,
            borderColor: '#094A6E',
            borderStyle: "solid",
            borderRadius: 5,
            minHeight: 40,
          }}
          multiline
          autoFocus
          value={edit_name}
          onChange={(e) => setEdit_name(e.target.value)}
        />
        </Box>
      ) : (
   
        <Box display='flex' flexDirection='column' >
            <FormLabel
              sx={{
                fontSize: 28,
                pb:2,
                color: theme.palette.secondary.main,
                mt: 1,
                display:index != 0 ? 'none' : null
              }}
            >
              السؤال
            </FormLabel>
          <Box
            sx={{
              width: 150,
              borderWidth: 2,
              borderColor: '#094A6E',
              borderStyle: "solid",
              borderRadius: 5,
              minHeight: 40,
              p: "0px 5px",
            }}
          >
            <FormLabel
              sx={{
                fontSize: 20,
                color: '#094A6E',
                mt: 1,
              }}
            >
              {name}
            </FormLabel>
          </Box>
                    
        </Box>

      )}
      
      {editMode ? (
            <Box display='flex' flexDirection='column' >
            <FormLabel
              sx={{
                fontSize: 28,
                pb:2,
                color: theme.palette.secondary.main,
                mt: 1,
                display:index != 0 ? 'none' : null
              }}
            >
              الثقل
            </FormLabel>
        <FilledInput
          disableUnderline
          sx={{
            backgroundColor: "transparent",
            textAlign: "center",
            fontSize: 20,
            width: 150,
            borderWidth: 2,
            borderColor: '#094A6E',
            borderStyle: "solid",
            borderRadius: 5,
            height: 40,
          }}
          autoFocus
          value={edit_degree}
          onChange={(e) => setEdit_degree(e.target.value)}
        />
        </Box>
      ) : (
        <Box display='flex' flexDirection='column' >
        <FormLabel
          sx={{
            fontSize: 28,
            pb:2,
            color: theme.palette.secondary.main,
            mt: 1,
            display:index != 0 ? 'none' : null
          }}
        >
          الثقل
        </FormLabel>
        <Box
          sx={{
            width: 100,
            borderWidth: 2,
            borderColor: '#094A6E',
            borderStyle: "solid",
            borderRadius: 5,
            height: 40,
          }}
        >
          <InputLabel
            sx={{
              fontSize: 20,
              color: '#094A6E',
              mt: 1,
            }}
          >
            {degree}
          </InputLabel>
        </Box>
        </Box>
      )}{" "}
      {editMode ? (
            <Box display='flex' flexDirection='column' >
            <FormLabel
              sx={{
                fontSize: 28,
                pb:2,
                color: theme.palette.secondary.main,
                mt: 1,
                display:index != 0 ? 'none' : null
              }}
            >
              النوع
            </FormLabel>
       
        <Select
         dir='rtl'
         value={edit_is_multiple_answers}
         onChange={(e) => setis_multiple_answers(e.target.value)}
         disableUnderline
         placeholder='النوع'
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          height:40,
          width:220,
          m:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderWidth:0
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         endAdornment={<InputAdornment position={'end'}><img src={icon35} style={{
          marginTop:2,
          color:theme.palette.primary.main,
          width:'90%'
         }}/></InputAdornment>}
         >
           <MenuItem value='single' dir='rtl'>خيار واحد</MenuItem>
           <MenuItem value='multiple' dir='rtl'>متعدد الخيارات</MenuItem>
           <MenuItem value='custom' dir='rtl'>اخر</MenuItem>
         </Select>
        </Box>
      ) : (
        <Box display='flex' flexDirection='column' >
        <FormLabel
          sx={{
            fontSize: 28,
            pb:2,
            color: theme.palette.secondary.main,
            mt: 1,
            display:index != 0 ? 'none' : null
          }}
        >
          النوع
        </FormLabel>
        <Box
          component="div"
          // onClick={() => setOpenDialogue("المدربين", coaches_names)}
          sx={{
            width: 150,
            borderWidth: 2,
            borderColor: '#094A6E',
            borderStyle: "solid",
            borderRadius: 5,
            height: 40,
          }}
        >
          <InputLabel
            sx={{
              fontSize: 20,
              color: '#094A6E',
              mt: 1,
            }}
          >
            {is_multiple_answers == 'multiple' ? 'متعدد الخيارات' : is_multiple_answers == 'single' ? 'خيار واحد' : 'اخر'}
          </InputLabel>
        </Box>
        </Box>
      )}{" "}
      {editMode ? (
          null
      ) : (
        <Box display='flex' flexDirection='column' >
        <FormLabel
          sx={{
            fontSize: 28,
            pb:2,
            color: theme.palette.secondary.main,
            mt: 1,
            display:index != 0 ? 'none' : null
          }}
        >
          الأجوبة
        </FormLabel>
        <Box
          component="div"
          onClick={gettalents_by_skl_id}
          sx={{
            width: 150,
            borderWidth: 2,
            borderColor: '#094A6E',
            borderStyle: "solid",
            borderRadius: 5,
            height: 40,
          }}
        >
          <InputLabel
            sx={{
              fontSize: 20,
              color: '#094A6E',
              mt: 1,
            }}
          >
            مشاهدة
          </InputLabel>
        </Box>
        </Box>
      )}
      {editMode ? (
        <Button
           onClick={()=>{
            updateQuestion({id,name:edit_name,edit_degree,edit_is_multiple_answers})
          }}
          sx={{ fontSize: 22 ,alignSelf:'flex-end'}}
        >
          حفظ
        </Button>
      ) : null}
      {editMode ? null : (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{ fontSize: 22 ,alignSelf:'flex-end'}}
           onClick={()=>DeleteQuestion(id)}
        >
          <DeleteOutlined
            sx={{
              color: '#094A6E',
              fontSize: 32,
            }}
          />
        </IconButton>
      )}
      {editMode ? null : (
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{ fontSize: 22 ,alignSelf:'flex-end'}}
          onClick={()=>setEditMode(!editMode)}
        >
          <BorderColor
            sx={{
              color: '#094A6E',
              fontSize: 32,
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default Question;
