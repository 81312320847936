import { BorderColor, DeleteOutlined } from '@mui/icons-material'
import { Box, Button, FilledInput, IconButton, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import { theme } from '../../../../theme'

const City = ({
    id,
    name,
    DeleteCity,
    UpdateCity
}) => {
    const [editMode,setEditMode] = useState(false)
    const [value,setValue] = useState(name)
    return (
        <Box display="flex" justifyContent="center" columnGap={editMode ? 10 : 1}>
        <Box
          sx={{
            width: 200,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderRadius: 5,
          }}
        >
            {
                editMode ?
                <FilledInput disableUnderline
         sx={{backgroundColor:'transparent',
                textAlign:'center',
                fontSize:24
            }} autoFocus value={value} onChange={(e)=>setValue(e.target.value)}/>
                :
             <InputLabel
            sx={{
              fontSize: 24,
              color: theme.palette.secondary.main,  
              mt:1           
            }}
          >
            {name}
          </InputLabel>
            }
         
        </Box>
        {
            editMode ? <Button onClick={()=>{
                UpdateCity({id,name:value})
            }} sx={{fontSize:22}}>حفظ</Button>
            :null
        }
        {
             editMode ? null
             : <IconButton
             color="primary"
             aria-label="upload picture"
             component="span"
             onClick={()=>DeleteCity(id)}
           >
             <DeleteOutlined
               sx={{
                 m: 1,
                 color: theme.palette.secondary.main,
                 fontSize: 32,
               }}
             />
           </IconButton> 
        }
          {
            editMode ? null
            :  <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={()=>setEditMode(!editMode)}
          >
              
            <BorderColor
              sx={{
                m: 1,
                color: theme.palette.secondary.main,
                fontSize: 32,
              }}
            />
          </IconButton>
        }
       


      </Box>
    )
}

export default City
