import { Box, FormLabel } from '@mui/material'
import React from 'react'
// import Logo_1 from '../../../../assets/images/photo_png1.png'
import text_1 from '../../../../demo/text'
import { theme } from '../../../../theme'

const Item = ({title,img,mb,txt,ms,as}) => {
    return (
      <Box sx={{alignSelf:{xs:'',sm:'',md:as,lg:as},width:{xs:'100%',sm:'100%',md:'20%',lg:'20%'},mt:mb ? -4 : ms && 2}}>
          <Box justifyContent='flex-end' display='flex' columnGap={3}>
          <Box dir='rtl' flex={1} sx={{alignSelf:'center'}}>
                <FormLabel sx={{color:theme.palette.primary.main,fontSize:42,}}>
                    {title}
                </FormLabel>
            </Box>
            <Box>
                <img src={img}/>
            </Box>
          </Box>

          <Box dir='rtl'>
            <FormLabel sx={{color:theme.palette.secondary.main}}>{txt}</FormLabel>
          </Box>
      </Box>
    )
}

export default Item
