import { AccountCircle } from '@mui/icons-material'
import { Box, Container, FilledInput, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import _Button from '../../../components/Button'
import { add_Nationality } from '../../../features/nationalitiesSlice'
import { theme } from '../../../theme'

export const NewNationality = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [arabic_name,setarabic_name] = useState('')
    const [english_name,setenglish_name] = useState('')
    const {add_data:{success}} = useSelector(state=>state.nationalities)
    const createNationality=async()=>{
      dispatch(add_Nationality({arabic_name,english_name}))
    }
    useEffect(() => {
     if(success){
      navigate('/dashboard/nationalities')
     }
    }, [success])
    return (
        <Box dir='rtl' style={{justifyContent:'center',display:'flex'}}>

        <Box 
        height='100px'
        style={{
            width:'50%',
            height:'300px',
            background:'white',
            borderRadius:50,
            textAlign:'center',
            alignItems:'center',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center'
        }}
        >
            <FilledInput
            value={arabic_name}
            placeholder={'الاسم بالعربية'}
            onChange={(e)=>setarabic_name(e.target.value)}
         disableUnderline
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          height:40,
          m:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white'
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
        //  startAdornment={<InputAdornment position={'start'}><AccountCircle/></InputAdornment>}
         ></FilledInput>
             <FilledInput
             placeholder={'الاسم بالاكليزية'}
            value={english_name}
            onChange={(e)=>setenglish_name(e.target.value)}
         disableUnderline
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          height:40,
          m:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white'
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
        //  startAdornment={<InputAdornment position={'start'}><AccountCircle/></InputAdornment>}
         ></FilledInput>
             <_Button onClick={createNationality}>انشاء</_Button>
            
        </Box>

        </Box>
    )
}
