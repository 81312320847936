import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../util/API";

const initialState = {
data:{
  token:'',
  user:{
    role_name:'',
    first_name:''
  }
},
loading:false,
error:false
};

export const signup = createAsyncThunk(
    "auth/register",
    async (data) => {
      try {
        
        let res = await API.post('register',data)
        console.log(res)
        if(res.data?.success){
            return res.data.data;
        }
      
      } catch (err) {
        if (!err.response) {
          throw err;
        }
        console.log(err)
        return err?.response?.data
      }
    }
  )

  export const signin = createAsyncThunk(
    "auth/login",
    async (data) => {
      try {
        let res = await API.post('login',data)
        if(res.data?.success){
          return res.data.data
      }
      } catch (err) {
        if (!err.response) {
          throw err;
        }
        console.log(err);
        return err?.response?.data

      }
    }
  )

export const authSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    emptyError:(state)=>state.data.errors = {}
  },
  extraReducers: {
    [signin.pending]: (state) => {
      state.data={}
      state.loading = true;
      state.error = false;
    },
    [signin.fulfilled]: (state, action) => {
      state.data = action.payload
      localStorage.setItem('token',state.data.token)
      state.loading = false;
      state.error = false;
    },
    [signin.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    [signup.pending]: (state) => {
      state.data = {}
      state.loading = true;
      state.error = false;
    },
    [signup.fulfilled]: (state, action) => {
      state.data = action.payload
      state.loading = false;
      state.error = false;
    },
    [signup.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { emptyError } = authSlice.actions;

export default authSlice.reducer;
