import { Box, Hidden, InputLabel, Paper } from "@mui/material";
import React from "react";
import VisitorHeader from "./header/VisitorHeader";
import background_1 from "../assets/images/Canvas.jpg";
import style from "../App.module.css";
import _Button from "../components/Button";
import { Navigate, Route, Routes, useLocation } from "react-router";
import Home from "../Pages/visitor/Home";
import AboutUs from "../Pages/visitor/aboutus/AboutUs";
import ContactUs from "../Pages/visitor/ContactUs";
import Login from "../Pages/Login";
import ButtonAppBar from "./VisitorLayout_Phone";
// import Register from "../Pages/register";
import NewsPage from "../Pages/news";
import { useSelector } from "react-redux";
import VisitorFooter from "./footer/VisitorFooter";
import OTP from "../Pages/OTP/OTP";
import SideBar from "../components/SideBar";
import Cities from "../Pages/dashboard/cities/Cities";
import { theme } from "../theme";
import SideBarTrainer from "../components/SideBarTrainer";
import RegisterForm from "../Pages/register/components/RegisterForm";
import Register from "../Pages/register";
import HeaderInfo from "../components/HeaderInfo";
import { NewCity } from "../Pages/dashboard/cities/NewCity";
import { About_project } from "../Pages/visitor/aboutProject";
import Nationalities from "../Pages/dashboard/nationalities/Nationalities";
import { NewNationality } from "../Pages/dashboard/nationalities/NewNationality";
import Sports from "../Pages/dashboard/sports/Sports";
import { NewSport } from "../Pages/dashboard/sports/NewSport";
import Schools from "../Pages/dashboard/schools/Schools";
import Questions from "../Pages/dashboard/questions/Questions";
import { NewQuestion } from "../Pages/dashboard/questions/NewQuestion";
import Evaluations from "../Pages/dashboard/evaluation_crud/Evaluations";
import { NewEvaluation } from "../Pages/dashboard/evaluation_crud/NewEvaluation";
const VisitorLayout = () => {
  const location = useLocation();
  const accessToken = useSelector((state) => state.auth?.data?.token);
  const firstName = useSelector((state) => state.auth?.data?.user?.first_name);
  const role_name = useSelector((state) => state.auth?.data?.role_name);

  return (
    <Box
      className={
        location.pathname == "/register" || location.pathname == "/news" || location.pathname == "/aboutus" || location.pathname == "/aboutprogram"
        || location.pathname == '/dashboard/cities' || location.pathname == '/dashboard/schools' 
        ? style.paper_1
          : location.pathname == "/" ? style.paper2 : style.paper
      }
    >
      <header>
        <Hidden smDown mdDown>
            <VisitorHeader />
        </Hidden>
        <Hidden mdUp>
            <ButtonAppBar />
        </Hidden>
        {
          location.pathname == '/dashboard'
          ?<HeaderInfo />
          :null

        }
        
      </header>
          <Routes>
            <Route path='/' element={<UnRequireAuth token={accessToken}><Home/></UnRequireAuth>}/>
            <Route path='/login' element={<UnRequireAuth token={accessToken}><Login/></UnRequireAuth>}/>
            <Route path='/register' element={<UnRequireAuthOtp token={accessToken}><Register/></UnRequireAuthOtp>}/>
            <Route path='/aboutus' element={<AboutUs/>}/>
            <Route path='/contactus' element={<ContactUs/>}/>
            <Route path='/about-project' element={<About_project/>}/>
            <Route path='/otp' element={<OTP/>}/>

            <Route path='/dashboard' element={role_name == 'talent' ? null : role_name == 'superadmin' ? <SideBar /> : role_name == 'coach' ?  <SideBarTrainer/> : null}>
              <Route path='cities'>
                <Route index element={<Cities/>}/>
              <Route path='new-city' element={<NewCity/>}/>
              </Route>
              <Route path='nationalities'>
                <Route index element={<Nationalities/>}/>
              <Route path='new-nationality' element={<NewNationality/>}/>
              </Route>
              <Route path='sports'>
                <Route index element={<Sports/>}/>
              <Route path='new-sport' element={<NewSport/>}/>
              </Route>
              <Route path='schools'>
                <Route index element={<Schools/>}/>
              <Route path='new-school' element={<NewSport/>}/>
              </Route>
              <Route path='questions'>
                <Route index element={<Questions/>}/>
              <Route path='new-question' element={<NewQuestion/>}/>
              </Route>
              <Route path='evaluations'>
                <Route index element={<Evaluations/>}/>
              <Route path='new-evaluation' element={<NewEvaluation/>}/>
              </Route>
            </Route>

          </Routes>
    </Box>
  );
};

export default VisitorLayout;

function UnRequireAuth({ children, token }) {
  let location = useLocation();
  if (token) {
    return (
      <Navigate
        to={`/dashboard`}
        state={{ from: location }}
        replace
      />
    );
  } else {
    return children;
  }
}
function UnRequireAuthOtp({ children, token }) {
  let location = useLocation();
  if (token) {
    return <Navigate to={`/otp`} state={{ from: location }} replace />;
  } else {
    return children;
  }
}
function RequireAuth({ children, token }) {
  let location = useLocation();
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
}
