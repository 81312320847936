import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../util/API";


const initialState = {
data:[],
loading:false,
error:false
};

export const getScheduled = createAsyncThunk(
    "scheduledSlice/get",
    async (data) => {
      console.log('====>',data.school_id)
      console.log(data.class_id)
      try {
        let res = await API.get('getschedulebyschoolclassid/'+data.school_id+'/'+data.class_id)
        if(res.data.success){
          return res.data.data
        }
      } catch (err) {
        if (!err.response) {
        }
      }
    }
  )

export const scheduledSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
  },
  extraReducers: {
    [getScheduled.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getScheduled.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getScheduled.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    }
  },
});

export const {  } = scheduledSlice.actions;

export default scheduledSlice.reducer;
