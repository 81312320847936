import { AddCircle, BorderColor, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormLabel,
  IconButton,
  InputLabel,
  Pagination,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getQuestions } from "../../../features/questionsSlice";
import { theme } from "../../../theme";
import API from "../../../util/API";
import Question from "./components/Question";


const Questions = () => {
  const token = useSelector(state=>state.auth?.data?.token)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const state = useSelector((state) => state.questionsSlice);
  const [page,setPage]=useState(1)
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getQuestions({paginate:1,page:page}));
  }, [dispatch,page]);
  useEffect(() => {
    setQuestions(state.data);
  }, [state.data]);
  const DeleteQuestion = async (id) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await API.get('deletequestion/'+id);
      if (response.data.success) {
        // setQuestions(questions.data.filter((q) => q.id != id));
        dispatch(getQuestions({paginate:1,page:page}));
      }
    }
  };

  const updateQuestion = async (data) => {
    let body={
      question:data.name,
      question_degree:data.edit_degree,
      is_multiple_answers:data.edit_is_multiple_answers
    }
    console.log({body})
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await API.post('editquestion/'+data.id,body,{headers:{Authorization:`Bearer ${token}`}});
      console.log({response})
      if (response.data.success) {
        dispatch(getQuestions({paginate:1,page:page}));
      }
    }
  };
  return (
    <Box display="flex" flexDirection="column" rowGap={2} >
      <Box dir="rtl" textAlign="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
            }}
          >
            اضافة سؤال
          </InputLabel>
          <IconButton
            color="secondary"
            component="span"
            onClick={() => navigate("/dashboard/questions/new-question")}
          >
            <AddCircle
              sx={{ olor: theme.palette.secondary.main, fontSize: 32 }}
            />
          </IconButton>
        </Box>
        <Box mt={3}> 
          {
            state.loading ? (
              <Box mb={20}>
                  <CircularProgress />
                </Box>
            
            ) : 
            <Box display='flex' flexDirection='column' rowGap={2} >
          {
          questions && questions.data?.length > 0 ? (
            questions.data?.map((question,index) => (
              <Question
              index={index}
              key={question.id}
              id={question.id}
              name={question.name}
              degree={question.degree}
              is_multiple_answers={question.is_multiple_answers}
              answers={question.answers}
              DeleteQuestion={DeleteQuestion}
              updateQuestion={updateQuestion}
              />
            ))
          ) : (
            <label>no data</label>
          )}
          </Box>
          }
          
        </Box>
      </Box>
      <Box alignSelf="center" marginBottom={10} marginTop={5}>
        <Pagination count={state.data.last_page} siblingCount={0} onChange={handleChange} page={page} />
      </Box>
    </Box>
  );
};

export default Questions;
