import {  Box, CircularProgress, Container, FormLabel, Hidden, InputLabel, Paper, TextField } from '@mui/material'
import React, { useState } from 'react'
import img_2 from '../../assets/images/photo_png4.png'
import _Button from '../../components/Button'
import SocialMedia from '../../layout/footer/SocialMedia'
import { theme } from '../../theme'
import API from '../../util/API'
const ContactUs = () => {
    let style={
        m:1,
        borderRadius:4,
        opacity:0.5
    }
    let style1={
        '& .MuiInputBase-root':{
        
          borderRadius:10,height:30,width:{xs:300,sm:450,md:450,lg:450}},
        width:{xs:300,sm:450,md:450,lg:450},
        height:30,
        '& .MuiOutlinedInput-notchedOutline':{
            borderColor:theme.palette.primary.main,
        borderWidth:1.5,
        borderStyle:'solid',

        },
        '& .MuiOutlinedInput-notchedOutline::hover':{
          borderColor:theme.palette.secondary.main,
          borderWidth:2
        }
      
    }
    let style2={
      '& .MuiInputBase-root':{borderRadius:4,height:180},
      width:{xs:300,sm:450,md:450,lg:450},
      '& .MuiOutlinedInput-notchedOutline':{
        borderColor:theme.palette.primary.main,
    borderWidth:1.5,
    borderStyle:'solid',

    }
    }

    const [loading,setLoading]=useState(false)
    const [ShowMsg,setShowMsg]=useState(false)
    const [first_name, setfirst_name] = useState('')
    const [last_name, setlast_name] = useState('')
    const [subject, setsubject] = useState('')
    const [message, setmessage] = useState('')

    const sendEmail =async()=>{

        try{
          setLoading(true)
          let res = await API.post('mail',{first_name,last_name,subject,message})
          setLoading(false)
          if(res?.data?.success){
          setShowMsg(true)
          }
        }catch(e){
          setLoading(false)
        }
    }
    return (
      <Container>
      <Container sx={{textAlign:'center'}}>
    <Container dir='rtl' sx={{display:'flex',justifyContent:'flex-end',marginTop:{xs:0,sm:0,lg:0,md:0}}}>
      <Box alignSelf='flex-end'>
        <Hidden smDown xsDown mdDown>
        <img src={img_2} width='18%' style={{alignSelf:'flex-end',position:'fixed',bottom:0,right:200}}/>
        </Hidden>
       
      </Box>
      <Box alignSelf='flex-end'>
        {
          ShowMsg ? 
        <FormLabel sx={{color:theme.palette.success.main,fontSize:22}}>تم ارسال الرسالة بنجاح</FormLabel>
          :null
        }
        <Paper sx={style}>
        <TextField onChange={(e)=>setfirst_name(e.target.value)} placeholder='الاسم الاول' sx={style1}></TextField>
        </Paper>
        <Paper sx={style}>
        <TextField onChange={(e)=>setlast_name(e.target.value)} placeholder='الاسم الأخير' sx={style1}></TextField>
        </Paper>
        <Paper sx={style}>
        <TextField onChange={(e)=>setsubject(e.target.value)} placeholder='الموضوع' sx={style1}></TextField>
        </Paper>
        <Paper  sx={[style]}>
        <TextField onChange={(e)=>setmessage(e.target.value)}
          id="outlined-multiline-static"
          // label="Multiline"
          multiline
          rows={7}  
          placeholder='الرسالة'
          sx={[style2,{"& ::placeholder":{fontWeight:'bold'}}]}
        />
        </Paper>
        <Box display='flex' justifyContent='space-evenly'>
          {
            loading ? <CircularProgress />
            : 
          <_Button
          disabled={
            first_name == '' || last_name == '' || subject =='' || message == ''
          }
          onClick={sendEmail} width={100} backgroundColor={theme.palette.secondary.main}>ارسال</_Button>
          }
        </Box>
        </Box>
    </Container>

    </Container> 
    <Box mt={5} position='relative' >
       <Hidden smUp mdUp>
        <img src={img_2} width={160} style={{alignSelf:'flex-end',position:'fixed',bottom:0,}}/>
        </Hidden>
    </Box>
    <SocialMedia />
      
    </Container>
    )
}

export default ContactUs
