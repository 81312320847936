import { BorderColor, CalendarMonth, DeleteOutlined } from '@mui/icons-material'
import { Box, Button, FilledInput, IconButton, InputAdornment, InputLabel } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React, { useState } from 'react'
import { theme } from '../../../../theme'

const Evaluation = ({
    id,
    name,
    start_dateP,
    end_datep,
    DeleteEvaluation,
    UpdateEvaluation
}) => {
    const [editMode,setEditMode] = useState(false)
    const [value,setValue] = useState(name)
    const [start_date,setstart_date] = useState(start_dateP)
    const [open_start_date,setOpenStart_date] = useState(false)

    const [end_date,setend_date] = useState(end_datep)
    const [open_end_date,setOpenEnd_date] = useState(false)

    return (
        <Box display="flex" justifyContent="center" columnGap={editMode ? 1 : 1}>
        <Box
          sx={{
            width: 200,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderRadius: 5,
          }}
        >
            {
                editMode ?
                <FilledInput disableUnderline
         sx={{backgroundColor:'transparent',
                textAlign:'center',
                fontSize:24
            }} autoFocus value={value} onChange={(e)=>setValue(e.target.value)}/>
                :
             <InputLabel
            sx={{
              fontSize: 24,
              color: theme.palette.secondary.main,  
              mt:1           
            }}
          >
            {name}
          </InputLabel>
            }
         
        </Box>
        <Box
                sx={{
                  width: 200,
                  borderWidth: 2,
                  borderColor: theme.palette.primary.main,
                  borderStyle: "solid",
                  borderRadius: 5,
                }}
              >
            {
                editMode ?
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                   
                  // components={<TextField/>}
                  minDate={moment(new Date())}
                  onClose={()=>setOpenStart_date(false)}
                  closeOnSelect={(newValue)=>{
                    setstart_date(newValue);
                    setOpenStart_date(false)
                  }}
               sx={{
                backgroundColor:'white',
                borderRadius:40,
                width:{xs:'15rem',sm:'15rem',md:200,lg:200},
                border:'2px solid',
                
                borderColor:theme.palette.primary.main,
                '&.Mui-focused':{
                  backgroundColor:'white',
                  // borderColor:theme.palette.secondary.main
              
                },
                '&.Mui-root':{
                  borderWidth:0
                },
                width:{xs:'15rem',sm:'15rem',md:200,lg:200}
               }}
                  value={start_date}
                  open={open_start_date}
                  onChange={(newValue) => {
                    setstart_date(newValue);
                    setOpenStart_date(false)
                  }}
                  renderInput={(params) => <FilledInput
                    sx={{backgroundColor:'transparent',
                    textAlign:'center',
                    fontSize:24
                }}
                    startAdornment={<InputAdornment position={'start'}><CalendarMonth /></InputAdornment>}
                  
                    disableUnderline onClick={()=>setOpenStart_date(true)}   {...params} />}
                /> 
              </LocalizationProvider>
                :

             <InputLabel
            sx={{
              fontSize: 24,
              color: theme.palette.secondary.main,  
              mt:1           
            }}
          >
            {start_date}
          </InputLabel>

            }
                   </Box>
    
        <Box
          sx={{
            width: 200,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderRadius: 5,
          }}
        >
            {
                editMode ?
                <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                   
                  // components={<TextField/>}
                  minDate={moment(new Date())}
                  onClose={()=>setOpenEnd_date(false)}
                  closeOnSelect={(newValue)=>{
                    setend_date(newValue);
                    setOpenEnd_date(false)
                  }}
               sx={{
                backgroundColor:'white',
                borderRadius:40,
                width:{xs:'15rem',sm:'15rem',md:200,lg:200},
                border:'2px solid',
                
                borderColor:theme.palette.primary.main,
                '&.Mui-focused':{
                  backgroundColor:'white',
                  // borderColor:theme.palette.secondary.main
              
                },
                '&.Mui-root':{
                  borderWidth:0
                },
                width:{xs:'15rem',sm:'15rem',md:200,lg:200}
               }}
                  value={end_date}
                  open={open_end_date}
                  onChange={(newValue) => {
                    setend_date(newValue);
                    setOpenEnd_date(false)
                  }}
                  renderInput={(params) => <FilledInput
                    sx={{backgroundColor:'transparent',
                    textAlign:'center',
                    fontSize:24
                }}
                    startAdornment={<InputAdornment position={'start'}><CalendarMonth /></InputAdornment>}
                  
                    disableUnderline onClick={()=>setOpenEnd_date(true)}   {...params} />}
                /> 
              </LocalizationProvider>
                :
             <InputLabel
            sx={{
              fontSize: 24,
              color: theme.palette.secondary.main,  
              mt:1           
            }}
          >
            {end_date}
          </InputLabel>
            }
         
        </Box>
        {
            editMode ? <Button onClick={()=>{
             UpdateEvaluation({id,evaluation:value,
              start_date:moment(start_date).format('yyyy-MM-DD'),
              end_date:moment(end_date).format('yyyy-MM-DD')})
            }} sx={{fontSize:22}}>حفظ</Button>
            :null
        }
        {
             editMode ? <IconButton
             color="primary"
             aria-label="upload picture"
             component="span"
             onClick={()=>DeleteEvaluation(id)}
           >
             <DeleteOutlined
               sx={{
                 m: 1,
                 color: theme.palette.secondary.main,
                 fontSize: 32,
               }}
             />
           </IconButton> 
             : <IconButton
             color="primary"
             aria-label="upload picture"
             component="span"
             onClick={()=>DeleteEvaluation(id)}
           >
             <DeleteOutlined
               sx={{
                 m: 1,
                 color: theme.palette.secondary.main,
                 fontSize: 32,
               }}
             />
           </IconButton> 
        }
          {
            editMode ? null
            :  <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={()=>setEditMode(!editMode)}
          >
              
            <BorderColor
              sx={{
                m: 1,
                color: theme.palette.secondary.main,
                fontSize: 32,
              }}
            />
          </IconButton>
        }
       


      </Box>
    )
}

export default Evaluation
