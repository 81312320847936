import { CalendarMonth } from '@mui/icons-material'
import { Box, FilledInput, FormLabel, InputAdornment } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import _Button from '../../../components/Button'
import { add_Evaluation } from '../../../features/evaluationsSlice'
import { theme } from '../../../theme'

export const NewEvaluation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name,setName] = useState('')
    const [start_date,setstart_date] = useState(moment(new Date()))
    const [open_start_date,setOpenStart_date] = useState(false)

    const [end_date,setend_date] = useState(start_date)
    const [open_end_date,setOpenEnd_date] = useState(false)
    const {add_data:{success}} = useSelector(state=>state.evaluationsSlice)
    const createEvaluation=async()=>{
      dispatch(add_Evaluation({evaluation:name,start_date:moment(start_date).format('yyyy-MM-DD'),
    end_date:moment(end_date).format('yyyy-MM-DD')
    }))
    }
    useEffect(() => {
     if(success){
      navigate('/dashboard/evaluations')
     }
    }, [success])
    return (
        <Box dir='rtl' pb={40} style={{justifyContent:'center',display:'flex',}}>

        <Box 
        height='100px'
        style={{
            width:'50%',
            height:'300px',
            background:'white',
            borderRadius:50,
            textAlign:'center',
        }}
        >
          <Box
           style={{

            alignItems:'center',
            display:'flex',
            justifyContent:'center'
        }}
          >

            <Box display='flex' flexDirection='column' >
            <FormLabel
              sx={{
                fontSize: 28,
                color: theme.palette.secondary.main,
               mt:5,

              }}
            >
              السؤال
            </FormLabel>
       
            <FilledInput
            placeholder='اسم التقييم'
            value={name}
            onChange={(e)=>setName(e.target.value)}
         disableUnderline
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          height:40,
          m:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white'
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
        //  startAdornment={<InputAdornment position={'start'}><AccountCircle/></InputAdornment>}
         ></FilledInput>
            </Box>
            <Box display='flex' flexDirection='column' >
            <FormLabel
              sx={{
                fontSize: 28,
                color: theme.palette.secondary.main,
               mt:5,

              }}
            >
              تاريخ البدء
            </FormLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                   
                  // components={<TextField/>}
                  minDate={moment(new Date())}
                  onClose={()=>setOpenStart_date(false)}
                  closeOnSelect={(newValue)=>{
                    setstart_date(newValue);
                    setOpenStart_date(false)
                  }}
               sx={{
                backgroundColor:'white',
                borderRadius:40,
                width:{xs:'15rem',sm:'15rem',md:200,lg:200},
                border:'2px solid',
                
                borderColor:theme.palette.primary.main,
                '&.Mui-focused':{
                  backgroundColor:'white',
                  // borderColor:theme.palette.secondary.main
              
                },
                '&.Mui-root':{
                  borderWidth:0
                },
                width:{xs:'15rem',sm:'15rem',md:200,lg:200}
               }}
                  value={start_date}
                  open={open_start_date}
                  onChange={(newValue) => {
                    setstart_date(newValue);
                    setOpenStart_date(false)
                  }}
                  renderInput={(params) => <FilledInput
                    sx={{backgroundColor:'transparent',
                    textAlign:'center',
                    fontSize:24
                }}
                    startAdornment={<InputAdornment position={'start'}><CalendarMonth /></InputAdornment>}
                  
                    disableUnderline onClick={()=>setOpenStart_date(true)}   {...params} />}
                /> 
              </LocalizationProvider>
              </Box>
              <Box display='flex' flexDirection='column' >
            <FormLabel
              sx={{
                fontSize: 28,
                color: theme.palette.secondary.main,
               mt:5,

              }}
            >
              تاريخ الانتهاء
            </FormLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                   
                  // components={<TextField/>}
                  minDate={moment(start_date)}
                  onClose={()=>setOpenEnd_date(false)}
                  closeOnSelect={(newValue)=>{
                    setend_date(newValue);
                    setOpenEnd_date(false)
                  }}
               sx={{
                backgroundColor:'white',
                borderRadius:40,
                width:{xs:'15rem',sm:'15rem',md:200,lg:200},
                border:'2px solid',
                
                borderColor:theme.palette.primary.main,
                '&.Mui-focused':{
                  backgroundColor:'white',
                  // borderColor:theme.palette.secondary.main
              
                },
                '&.Mui-root':{
                  borderWidth:0
                },
                width:{xs:'15rem',sm:'15rem',md:200,lg:200}
               }}
               value={start_date}
                  open={open_end_date}
                  onChange={(newValue) => {
                    setend_date(newValue);
                    setOpenEnd_date(false)
                  }}
                  renderInput={(params) => <FilledInput
                    sx={{backgroundColor:'transparent',
                    textAlign:'center',
                    fontSize:24
                }}
                    startAdornment={<InputAdornment position={'start'}><CalendarMonth /></InputAdornment>}
                  
                    disableUnderline onClick={()=>setOpenEnd_date(true)}   {...params} />}
                /> 
              </LocalizationProvider>
              </Box>

            
        </Box>
        <Box sx={{
          textAlign:'center',
          marginTop:5
        }}>

        </Box>
        <_Button style={{textAlign:'center'}} width={200} onClick={createEvaluation}>انشاء</_Button>
        </Box>
        </Box>
    )
}
