
import { Box, FilledInput, IconButton, iconButtonClasses, InputAdornment, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import _Button from '../../../../components/Button'
import { theme } from '../../../../theme'
import icon35 from '../../../../assets/images/PNG-35.png'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
export const Phase2 = ({setPhase,setAnswers,answers}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name,setName] = useState('')
    const [degree,setDegree] = useState('')
    const [type,setType] = useState('single')
    const [state,setState]=useState([]) 
    const [message,setMessage]=useState('')
    const {add_data:{success}} = useSelector(state=>state.cities)
    const addAnswer = (id) => {
      setMessage('')
      if(name != '' || degree != ''){
         let bool = state.find(s=>s.answer==name)
      if(!bool){
        setName('')
        setDegree('')
            setState([...state,...[{answer:name,answer_degree:degree}]])
    setAnswers([...state,...[{answer:name,answer_degree:degree}]])
      }else{
        setMessage(`${name} موجود مسبقا`)
      }
      }
     
    }
    const removeAnswer = (answer) => {
      setMessage('')
      setState(state.filter(s=>s.answer!=answer))
      setAnswers(answers.filter(a=>a.answer!=answer))
      }
      const editAnswer = (index,newValue) => {
        setMessage('')
        setState(
          state.splice(index, 1, {answer:newValue,answer_degree:state.index.answer_degree}))
        setAnswers(answers.splice(index, 1, {answer:newValue,answer_degree:state.index.answer_degree}))
        }

    return (

        <Box 
        style={{
            textAlign:'center',
            alignItems:'center',
        }}
        >
            <Box    style={{
            display:'flex',
            justifyContent:'center'
        }}>

           
            <FilledInput
            value={name}
            onChange={(e)=>setName(e.target.value)}
         disableUnderline
         placeholder='الجواب'
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          height:40,
          m:1,
          width:220,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white'
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
        //  startAdornment={<InputAdornment position={'start'}><AccountCircle/></InputAdornment>}
         ></FilledInput>
         <FilledInput
         type='number'
            value={degree}
            onChange={(e)=>setDegree(e.target.value)}
         disableUnderline
         placeholder='العلامة'
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          
          height:40,
          m:1,
          width:220,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
        //  startAdornment={<InputAdornment position={'start'}><AccountCircle/></InputAdornment>}
         ></FilledInput>
         <IconButton onClick={addAnswer}><AddCircleOutline size='large'/></IconButton>
           </Box>
           <p style={{color:'red'}}>{message}</p>
           <Box sx={{height:'200px'}} style={{overflow:'scroll',overflowX: 'hidden'}}>

        
           {
               answers.map((s,index)=>(
                   <Box style={{display:'flex', justifyContent:'space-around',borderBottom:'1px solid gray'}}>
                  <p style={{width:200,textAlign:'right'}}><span style={{margin:'0 5px'}}>الجواب {index+1}:</span>{s.answer}</p>
                  <p style={{width:200,textAlign:'right'}}><span style={{margin:'0 5px'}}>العلامة:</span>{s.answer_degree}</p>
         <IconButton onClick={()=>removeAnswer(s.answer)} style={{alignSelf:'center'}} height={40}><RemoveCircleOutline/></IconButton>
                   </Box>
               )).reverse()
           }
              </Box>
           </Box>
    )
}
