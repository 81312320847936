import axios from 'axios';

export default axios.create({
    baseURL: 'https://apis.intelligent-world.com/public_html/api/',
    headers:{
        Authorization:`Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw`
    }
})
// export default axios.create({
//     baseURL: 'https://api.takween-talents.com/api/',
//     headers:{
//         Authorization:`Bearer ${'Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw'}`
//     }
// })


