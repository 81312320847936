import { AccountCircle, CompressOutlined, ErrorSharp, LocationCityOutlined, Visibility, VisibilityOff,LocationOn, AlternateEmail, PhoneIphoneSharp, Lock, KeyboardArrowDown, CalendarMonth } from '@mui/icons-material'
import { Box, CircularProgress, Container, FilledInput, FormControl, FormLabel, Grid, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import { Formik, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import text_1 from '../../../demo/text'
import { theme } from '../../../theme'
import * as yup from 'yup';
import { makeStyles, styled } from '@mui/styles'
import _Button from '../../../components/Button'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getSchool } from '../../../features/shcoolsSlice'
import { getCities } from '../../../features/citiesSlice'
import { getClasses } from '../../../features/classesSlice'
import { getScheduled } from '../../../features/scheduledSlice'
import { emptyError, signup } from '../../../features/authSlice'
import icon05 from '../../../assets/images/PNG-05.png'
import icon24 from '../../../assets/images/PNG-24.png'
import icon25 from '../../../assets/images/PNG-25.png'
import icon35 from '../../../assets/images/PNG-35.png'
import icon40 from '../../../assets/images/PNG-40.png'
import './css.css'
const useStyles=makeStyles({
  label:{
    color:theme.palette.secondary.main,
  },
  
  label_1:{
    color:theme.palette.primary.main,
    marginRight:10
  },
  input:{
    backgroundColor:'white',
    borderRadius:40,
    
    border:'2px solid',
    
    borderColor:theme.palette.primary.main,
    '&.Mui-focused':{
      backgroundColor:'white'
    },
    '&.Mui-root':{
      borderWidth:0
    }
  },
  inputError:{
    backgroundColor:'white',
    borderRadius:40,
    
    border:'2px solid',
    
    borderColor:'red',
    '&.Mui-focused':{
      backgroundColor:'white'
    },
    '&.Mui-root':{
      borderWidth:0
    }
  },
  errorLabel:{
color:'red'
  },
  input_1:{
    backgroundColor:'white',
    // width:'26%',
    borderRadius:40,
    
    border:'2px solid',
    
    borderColor:theme.palette.primary.main,
    '&.Mui-focused':{
      backgroundColor:'white'
    }

  },
  error:{

  },
  icon:{
    marginTop:8,
    color:theme.palette.primary.main,
    width:'90%'
  },
  tag:{
    backgroundColor:theme.palette.primary.main,
    borderRadius:10
  },
  tag_text:{
    color:'white',
    padding:'20px 50px'
  },
  errorInput_1:{
    backgroundColor:'white',
    // width:'26%',
    borderRadius:40,
    
    border:'2px solid',
    
    borderColor:'red',
    '&.Mui-focused':{
      backgroundColor:'white'
    }
  }
})
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('ادخل  ايميل صحيح')
    .required('الايميل اجباري'),
    user_name: yup
    .string('Enter your email')
    .required('كلمة المستخدم اجباري'),
  password: yup
    .string('')
    .min(8, 'يجب ان تكون كلمة المرور مؤلفة من 8 ارقام')
    .required('كلمة المرور اجبارية'),
    password_confirmation: yup
    .string('')
    .min(8, 'يجب ان تكون تأكيد كلمة المرور مؤلفة من 8 ارقام')
    .required('تأكيد كلمة المرور اجبارية'),
    first_name: yup
    .string('')
    .required('الاسم الاول اجباري')
    .min(3,'الاسم الاول يجب ان يكون من 3 احرف على الاقل'),
    last_name: yup
    .string('')
    .required('اسم العائلة اجباري')
    .min(3,'يجب ان يكون اسم العائلة من 3 احرف على الاقل'),
    address_1:yup
    .string()
    .required('عنوان السكن اجباري'),
    address_2:yup
    .string()
    .required('الحي اجباري'),
    phone: yup
    .number('must be a number')
    .required('الهاتف اجباري'),
    // .min(3,'phone should be 9 of minimum  characters length'),
    // date:yup
    // .date()
    // .required('date is required'),
});

const RegisterForm = () => {

  const [first_name, setfirst_name] = useState('')
  const [father_name, setfather_name] = useState('mhammad')
  const [last_name, setlast_name] = useState('')
  const [user_name, setuser_name] = useState('jhgjhvbjhtth')
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')
  const [password_confirmation, setpassword_confirmation] = useState('')
  const [gender, setgender] = useState('female')
  const [phone, setphone] = useState('')
  const [address_1, setaddress_1] = useState('')
  const [address_2, setaddress_2] = useState('')
  const [nationality_id,setnationality_id]  = useState(1)

  const dispatch = useDispatch()
  const styles=useStyles()
  const [date,setDate] = useState(moment('2017-1-1'))
  const [openDate,setOpenDate]=useState(false)
  const [sched,setsched]=useState(false)
  const [show1,setshow1]=useState(false)
  const [show2,setshow2]=useState(false)
  const [sched1,setsched1]=useState(false)
  const [shcool_id,setSchool_id]=useState('')
  const [classes_by_schoolId,setClasses_by_schoolId]=useState([])
  const [city_id,setCity_id]=useState('')
  const [classId,setClassId]=useState('')
  const [classId_1,setClassId_1]=useState('')
  const [sheduled,setSheduled] = useState([])
  const [sheduled_1,setSheduled_1] = useState([])
  const {data,loading,error} = useSelector(state=>state.shcools)
  const {data:cities,loading:citiesLoading} = useSelector(state=>state.cities)
  const loading_register = useSelector(state=>state.auth.loading)
  const errorss = useSelector(state=>state.auth?.data?.errors)
  const [confirmMsg,setErrorConfirmPassword]=useState('')
  useEffect(()=>{
    dispatch(getSchool())
    dispatch(getCities())
  },[])
  const getClassesBySchoolId = async()=>{
    setClasses_by_schoolId([])
    let data = await dispatch(getClasses(shcool_id)).unwrap()
      setClasses_by_schoolId(data)
  }
  const getSheduledByachoolclassId= async()=>{
    setsched(true)
    let data = await dispatch(getScheduled({school_id:shcool_id,class_id:parseInt(classId)})).unwrap()
      setSheduled(data)
      setsched(false)
  }
  const getSheduledByachoolclassId_1 = async()=>{
    setsched1(true)
    let data = await dispatch(getScheduled({school_id:shcool_id,class_id:parseInt(classId_1)})).unwrap()
      setSheduled_1(data)
      setsched1(false)
  }
  useEffect(()=>{
    if(shcool_id){
      getSheduledByachoolclassId()
     }
  },[shcool_id,classId])
  useEffect(()=>{
   if(shcool_id){
      getSheduledByachoolclassId_1()
   }
  },[shcool_id,classId_1])
  useEffect(()=>{
    getClassesBySchoolId()
  },[shcool_id])
  const formik = useFormik({
    initialValues: {
      first_name:'',
      last_name:'',
      address_1:'',
      address_2:'',
      phone:'',
      password_confirmation:'',
      // first_name:'',
      email: '',
      password: '',
      username:''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.city_id = city_id
      values.school_id = shcool_id
      values.father_name='abc'
      values.nationality_id=nationality_id
      values.class_id=[classId,classId_1]
      values.city_id = city_id
      values.born_on=moment(date).format('yyyy-MM-DD')
      values.gender=gender
     values.phone = parseInt(values.phone)
    //  console.log({values})
     let data={
      first_name:values.first_name,
        last_name:values.last_name,
        father_name:'abc',
        user_name:values.user_name,
        nationality_id,
        city_id,
        email:values.email,
        gender,
        password:values.password,
        password_confirmation:values.password_confirmation,
        address:values.address_1+values.address_2,
        class_id:classId && !classId_1 ? [classId] : classId && classId_1 ? [classId,classId_1] : '',
         school_id:shcool_id,
        born_on:moment(date).format('yyyy-MM-DD'),
        phone:parseInt(values.phone)
     }
     if(values.password == values.password_confirmation){
      //  dispatch(emptyError())
      setErrorConfirmPassword('')

       dispatch(signup(data))
     }else{
       setErrorConfirmPassword('كلمة المرور ليست متطابقة')
     }
     
    },
  });
// console.log({
//   first_name,
//   last_name,
//   father_name,
//   user_name,
//   nationality_id,
//   city_id,
//   email,
//   gender,
//   password,
//   password_confirmation,
//   address:address_1+address_1,
//   address_2,
//   class_id:[classId,classId_1],
//    school_id:shcool_id,
//   born_on:moment(date).format('yyyy-MM-DD'),
//   phone

// })
const register = () =>{
  let data={
    first_name,
    last_name,
    father_name,
    user_name,
    nationality_id,
    city_id,
    email,
    gender,
    password,
    password_confirmation,
    address:address_1+address_1,
    address_2,
    class_id:[classId,classId_1],
     school_id:shcool_id,
    born_on:'2022-08-25',
    phone:parseInt(phone)
  }
  dispatch(signup(data))
}
const {errors,handleChange,handleBlur,touched,handleSubmit,values,submitForm}=formik
    return (
       <Container dir='rtl' sx={{
          //  textAlign:'center',
           marginLeft:'auto',
           marginRight:'auto',
           width:'100%'
       }}>
           <Box sx={{
               
               width:'100%'
           }}>
        <Box marginBottom='5%' textAlign='center'>
      <InputLabel
        color="primary"
        sx={{
          textAlign: "center",
          color: theme.palette.primary.main,
          fontSize: 36,

        }}
      >
        تسجيل المواهب
      </InputLabel>
      <FormLabel sx={{width:{xs:'5%',sm:'5%',md:'30%',lg:'40%'},color:theme.palette.secondary.main}}>
            {text_1}
                </FormLabel>
      </Box>
           </Box>
 {/* <Formik
   initialValues={{email:''}}
   validationSchema={validationSchema}
        onSubmit={(values)=>console.log('tfyt')}

 > */}
    {/* {({ submitForm, isSubmitting, validateOnChange,errors,handleChange,touched,handleBlur,values,handleSubmit}) =>( */}

   
           <form onSubmit={handleSubmit}>
           <Box flex='1' display='flex' flexDirection={{xs:'column',sm:'column',md:'row',lg:'row'}} justifyContent='center' >
          <Box flex='1' display='flex' alignItems={{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'}} flexDirection='column' rowGap={5}>
          <Box flex='1' display='flex' alignItems={{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'}} flexDirection='column' rowGap={2}>
      <Box>
        <InputLabel sx={(formik.touched.first_name && formik.errors.first_name ) || 
          (errorss?.first_name && errorss?.first_name[0])
          ? {color:'red'} : {}} className={styles.label}>الاسم </InputLabel>
        <FilledInput
        autoComplete='off'
        placeholder='الاسم'
        // onChange={(e)=>setfirst_name(e.target.value)}
        name="first_name"
        value={formik.values.first_name}
        onChange={formik.handleChange}
        onBlur={handleBlur}
         disableUnderline
        value={values.first_name}    
         
         sx={(touched.first_name && errors.first_name)
          || (errorss?.first_name && errorss?.first_name[0])
          ?  {
            paddingBottom:1,
          backgroundColor:'white',
          borderRadius:40,
          // 
          border:'2px solid',
          height:'3rem',
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main
          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
        
         } : {
          paddingBottom:1,
          height:'3rem',
          backgroundColor:'white',
          borderRadius:40,
          // 
          border:'2px solid',
          // 
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         startAdornment={<InputAdornment position={'start'}><img src={icon05} className={styles.icon}/></InputAdornment>}
         ></FilledInput>
         <br/>
         {
           touched.first_name && errors.first_name ? 
           <FormLabel sx={{color:'red'}}>{errors.first_name}</FormLabel>
           : errorss?.first_name && errorss?.first_name[0] ?
           <InputLabel sx={{color:'red'}}>{errorss?.first_name[0]}</InputLabel>
          : null
         }
        {/* <InputLabel>{touched.first_name}</InputLabel> */}
      </Box>

      <Box>
        <InputLabel sx={(touched.last_name && errors.last_name) 
          || (errorss?.last_name && errorss?.last_name[0])
          ? {color:'red'} : {}}  className={styles.label}>العائلة </InputLabel>
        <FilledInput
        autoComplete='off'
        // onChange={(e)=>setlast_name(e.target.value)}
        onChange={handleChange('last_name')}
        onBlur={handleBlur}
        name='last_name'
        placeholder='العائلة'
         disableUnderline
         sx={(touched.last_name && errors.last_name)
          || (errorss?.last_name && errorss?.last_name[0])
          ?  {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          paddingBottom:1,
          border:'2px solid',
          
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
        
         } : {
          backgroundColor:'white',
          borderRadius:40,
          paddingBottom:1,
          border:'2px solid',
          height:'3rem',
          
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         startAdornment={<InputAdornment position={'start'}><img src={icon05} className={styles.icon}/></InputAdornment>}
         ></FilledInput>
          {
           touched.last_name && errors.last_name ? 
           <InputLabel sx={touched.last_name && errors.last_name ? {color:'red'} : {}} >{errors.last_name}</InputLabel>
           : errorss?.last_name && errorss?.last_name[0] ?
           <InputLabel sx={{color:'red'}} >{errorss?.last_name[0]}</InputLabel>
           :null
         }
        <InputLabel></InputLabel>
      </Box>

      
      <Box>
        <InputLabel sx={touched.first_name && errors.first_name ? {color:'red'} : {}}  className={styles.label}>تاريخ الميلاد </InputLabel>
        <LocalizationProvider dateAdapter={AdapterMoment}>
  <DatePicker
     
    // components={<TextField/>}
    minDate={moment('2013-1-1')}
    maxDate={moment('2017-1-1')}
    onClose={()=>setOpenDate(false)}
    closeOnSelect={(newValue)=>{
      setDate(newValue);
      setOpenDate(false)
    }}
sx={{
  backgroundColor:'white',
  borderRadius:40,
  width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'},
  border:'2px solid',
  
  borderColor:theme.palette.primary.main,
  '&.Mui-focused':{
    backgroundColor:'white',
    borderColor:theme.palette.secondary.main

  },
  '&.Mui-root':{
    borderWidth:0
  },
  width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
 }}
    value={date}
    open={openDate}
    onChange={(newValue) => {
      setDate(newValue);
      setOpenDate(false)
    }}
    renderInput={(params) => <FilledInput
      startAdornment={<InputAdornment position={'start'}><CalendarMonth className={styles.icon}/></InputAdornment>}
    
      sx={{  backgroundColor:'white',
    borderRadius:40,
    width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'},
    border:'2px solid',
    height:'3rem',
    paddingBottom:1,
    borderColor:theme.palette.primary.main,
    '&.Mui-focused':{
      backgroundColor:'white',
      borderColor:theme.palette.secondary.main

    },
    '&.Mui-root':{
      borderWidth:0
    },
    width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}}} disableUnderline onClick={()=>setOpenDate(true)}   {...params} />}
  /> 
</LocalizationProvider>
        <InputLabel></InputLabel>
      </Box>
        </Box>

        <Box flex='1' display='flex' alignItems={{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'}} flexDirection='column' rowGap={2}>

      <Box>
        <InputLabel sx={touched.address_1 && errors.address_1 ? {color:'red'} : {}}  className={styles.label}>عنوان السكن </InputLabel>
        <FilledInput
        // onChange={(e)=>setaddress_1(e.target.value)}
        onChange={handleChange}
        onBlur={handleBlur}
        name='address_1'
        autoComplete='off'
        placeholder='عنوان السكن'
         disableUnderline
         sx={touched.address_1 && errors.address_1 ?  {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
      
         } : {
          backgroundColor:'white',
          borderRadius:40,
          paddingBottom:1,
          border:'2px solid',
          height:'3rem',
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         startAdornment={<InputAdornment position={'start'}><img src={icon24} className={styles.icon}/></InputAdornment>}
         ></FilledInput>
       {
           touched.address_1 && errors.address_1 ? 
           <InputLabel sx={{color:'red'}}>{errors.address_1}</InputLabel>
           : null
         }
      </Box>

      <Box>
        <InputLabel sx={touched.address_2 && errors.address_2 ? {color:'red'} : {}}  className={styles.label}>الحي </InputLabel>
        <FilledInput 
        autoComplete='off'
        onChange={handleChange}
        onBlur={handleBlur}
        name='address_2'
        autoComplete='off'
        placeholder='الحي'
         disableUnderline
         sx={touched.address_2 && errors.address_2 ?  {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
      
         } : {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         startAdornment={<InputAdornment position={'start'}><img src={icon24} className={styles.icon}/></InputAdornment>}
         ></FilledInput>
         {
           touched.address_2 && errors.address_2 ? 
             <InputLabel sx={{color:'red'}}>{errors.address_2}</InputLabel>
             :null
         }
      
      </Box>
      </Box>
      <Box flex='1' display='flex' alignItems={{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'}} flexDirection='column' rowGap={2}>

      <Box>
        <InputLabel sx={(errors.email && touched.email) || (errorss?.email && errorss?.email[0]) ? {color:'red'}:{}} className={styles.label}>البريد الالكتروني </InputLabel>
        <FilledInput 
        autoComplete='off'
        onChange={handleChange}
        onBlur={handleBlur}
        name='email'
        placeholder='البريد الالكتروني'
type='email'

        disableUnderline
        sx={(touched.email && errors.email) || (errorss?.email && errorss?.email[0]) ?  {
          backgroundColor:'white',
          borderRadius:40,
          paddingBottom:1,
          border:'2px solid',
          
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'},
          height:'3rem',
        } : {
          backgroundColor:'white',
          borderRadius:40,
          paddingBottom:1,
          border:'2px solid',
          height:'3rem',
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
        }}
         startAdornment={<InputAdornment position={'start'}><img src={icon40} className={styles.icon}/></InputAdornment>}
         ></FilledInput>
         {
           touched.email && errors.email ? 
           <InputLabel sx={{color:'red'}}>{errors.email}</InputLabel>
           : errorss?.email && errorss?.email[0] ?
           <InputLabel sx={{color:'red'}}>{errorss?.email[0]}</InputLabel>
          : null
         }
      </Box>
      <Box>
        <InputLabel sx={(touched.username && errors.username)
          ||
          (errorss?.user_name && errorss?.user_name[0])
          ? {color:'red'} : {}}>اسم المستخدم </InputLabel>
        <FilledInput 
        autoComplete='off'
        // onChange={(e)=>setusername(e.target.value)}
        onChange={handleChange}
        onBlur={handleBlur}
        name='user_name'
        placeholder='اسم المستخدم'
        // value={values.email ? values.email.split('@')[0] : ''}
         disableUnderline
         sx={(touched.user_name && errors.user_name)
          ||
          (errorss?.user_name && errorss?.user_name[0])
          ?  {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         } : {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         startAdornment={<InputAdornment position={'start'}><img src={icon05} className={styles.icon}/></InputAdornment>}
         ></FilledInput>
          {
           (touched.user_name && errors.user_name) 
          
           ? 
           <InputLabel sx={touched.user_name && errors.user_name ? {color:'red'} : {}} >{errors.user_name}</InputLabel>
           :  (errorss?.user_name && errorss?.user_name[0])
           ? 
           <InputLabel sx={{color:'red'}} >{errorss?.user_name[0]}</InputLabel>
            :null

         }
        <InputLabel></InputLabel>
      </Box>
          </Box>
          </Box>
        
            <Box flex='1' display='flex' alignItems={{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'}}  flexDirection='column' rowGap={9} >
          <Box>

          <Box>
        <InputLabel sx={touched.school_id && errors.school_id ? {color:'red'} : {}}  className={styles.label}>المدرسة </InputLabel>
        <Select 
        placeholder='المدرسة'
        startAdornment={<InputAdornment position={'start'}><img src={icon35} className={styles.icon}/></InputAdornment>}
         
         sx={!shcool_id && touched.school_id ?  {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
        
         } : {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         value={shcool_id}
        //  onChange={(e)=>setSchool_id(e.target.value)}
        onChange={(e)=>setSchool_id(e.target.value)}
        onBlur={handleBlur}
        name='school_id'
         >
           {
              loading ?  
    <MenuItem dir='rtl'>انتظرز...</MenuItem>
   : data && data.length > 0 ? data.map(d=>(
    <MenuItem dir='rtl' sx={{":hover":{backgroundColor:theme.palette.primary.main, color:'white'}}} value={d.id} key={d.id}>{d.name}</MenuItem>
  ))
  :
  null
   }
         </Select>
         {
           !shcool_id && touched.school_id ? 
           <InputLabel sx={{color:'red'}}>اختيار المدرسة اجباري</InputLabel>
           : null
         }
      </Box>
      <InputLabel sx={{marginTop:2,fontSize:20}} className={styles.label}>أنواع الرياضة</InputLabel>
   
          <InputLabel sx={(touched.classId && errors.classId)
            ||
            (errorss?.class_id && errorss?.class_id[0])
            ? {color:'red'} : {}}  className={styles.label_1}>الخيار الاول </InputLabel>
        <Select 
        disabled={classes_by_schoolId?.length == 0}
        placeholder='الاسم'
         sx={  (touched.classId && !classId) 
          ||
          (errorss?.class_id && errorss?.class_id[0])
          
          ? {
          backgroundColor:'white',
          // width:'26%',
          borderRadius:40,
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'},
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          }
         } : {
          backgroundColor:'white',
          // width:'26%',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'},
          paddingBottom:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          }
      
         }}

    // onChange={(e)=>setClassId(e.target.value)}  
    onChange={(e)=>{setClassId(e.target.value)}}
    onBlur={handleBlur}
    name='classId'   
         startAdornment={<InputAdornment position={'start'}><img src={icon35} className={styles.icon}/></InputAdornment>}
     value={classId}
         >
           {
              !shcool_id ?  
    <MenuItem sx={{minWidth:250  }} disabled dir='rtl'>ادخل المدرسة</MenuItem>
: classes_by_schoolId && classes_by_schoolId.length > 0 ? classes_by_schoolId.map(d=>(
  <MenuItem sx={{minWidth:250,":hover":{backgroundColor:theme.palette.primary.main,color:'white'  }}} dir='rtl' value={d.id} key={d.id}>{d.name}</MenuItem>
))
:
null
           }

         </Select>
         {
           touched.classId && !classId ? 
           <InputLabel sx={{color:'red'}}>ادخال الخيار الاول اجباري</InputLabel>
           : (errorss?.class_id && errorss?.class_id[0])
           ?  <InputLabel sx={{color:'red'}}>{errorss?.class_id[0]}</InputLabel>
          :null
         }
          <Box display='flex' flexDirection={{xs:'column',sm:'column',md:'row',lg:'row'}} rowGap={5}  columnGap={2} marginTop={3}>
            
          {
            sched ? <CircularProgress/>
            :
              sheduled?.map(s=>(
                 <Box key={s.id} display='flex' flexDirection='column' rowGap={2}>
            <Box className={styles.tag}>
            <InputLabel sx={{   color:'white',
    padding:'10px 10px', textAlign:'center'}} >{s.day_name}</InputLabel>
            </Box>
   
                  <Box className={styles.tag}>
                  {
              s.schedule.map(t=>(
                   <InputLabel className={styles.tag_text} sx={{   color:'white',
                   padding:'10px 10px',textAlign:'center'}}>{t.start_time.substring(0,5)}-{t.end_time.substring(0,5)}</InputLabel>
                   )) 
                  }
                   </Box>
            
          </Box>
              ))
            }
          </Box>
          </Box>

          <Box>

<InputLabel className={styles.label_1}>الخيار الثاني </InputLabel>
<Select 
placeholder='الاسم'

sx={{
  backgroundColor:'white',
  // width:'26%',
  borderRadius:40,
  paddingBottom:1,
  border:'2px solid',
  width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'},
  height:'3rem',
  borderColor:theme.palette.primary.main,
  '&.Mui-focused':{
    backgroundColor:'white',
    borderColor:theme.palette.secondary.main

  }

}}
disabled={classId == 0 || classes_by_schoolId?.filter(t=>t.id!=classId).length == 0 ? true : false}
onChange={(e)=>setClassId_1(e.target.value)}     
value={classId_1}
startAdornment={<InputAdornment position={'start'}><img src={icon35} className={styles.icon}/></InputAdornment>}

>
{

 classes_by_schoolId && classes_by_schoolId.length > 0 ? classes_by_schoolId.filter(t=>t.id!=classId).map(d=>(
 <MenuItem sx={{minWidth:250, ":hover":{ backgroundColor:theme.palette.primary.main,color:'white' }  }} dir='rtl' value={d.id} key={d.id}>{d.name}</MenuItem>
))
:
null
           }

</Select>
<Box display='flex' flexDirection={{xs:'column',sm:'column',md:'row',lg:'row'}} rowGap={5} columnGap={2} marginTop={3}>

{
  sched1 ? <CircularProgress/>
  :
              sheduled_1?.map(s=>(
                 <Box key={s.id} display='flex' flexDirection='column' rowGap={2}>
            <Box className={styles.tag}>
            <InputLabel sx={{   color:'white',
    padding:'10px 10px', textAlign:'center'}} >{s.day_name}</InputLabel>
            </Box>
   
                  <Box className={styles.tag}>
                  {
              s.schedule.map(t=>(
                   <InputLabel className={styles.tag_text} sx={{   color:'white',
                   padding:'10px 10px',textAlign:'center'}}>{t.start_time.substring(0,5)}-{t.end_time.substring(0,5)}</InputLabel>
                   )) 
                  }
                   </Box>
            
          </Box>
              ))
            }
         

</Box>
</Box>
<Box flex='1' display='flex' alignItems='flex-start' flexDirection='column' rowGap={5}>

<Box flex='1' display='flex' alignItems={{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'}} flexDirection='column' rowGap={2}>

<Box>
        <InputLabel sx={(touched.first_name && errors.first_name)
          || (errorss?.city_id && errorss?.city_id[0])
          ? {color:'red'} : {}}  className={styles.label}>المدينة </InputLabel>
        <Select 
        
        placeholder='المدينة'

         sx={(touched.city_id && !city_id) ||
          (errorss?.city_id && errorss?.city_id[0])
          ?  {backgroundColor:'white',
          paddingBottom:1,
         borderRadius:40,
         height:'3rem',
         border:'2px solid',
         borderColor:'red',
         '&.Mui-focused':{
           backgroundColor:'white',
           borderColor:theme.palette.secondary.main

         },
         '&.Mui-root':{
           borderWidth:0
         },
         width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
        } : {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         value={city_id}
         name='city_id'
         onBlur={handleBlur}
         onChange={(e)=>setCity_id(e.target.value)}
         startAdornment={<InputAdornment position={'start'}><img src={icon35} className={styles.icon}/></InputAdornment>}
        
        >
                    {
              citiesLoading ?  
    <MenuItem dir='rtl'>انتظرز...</MenuItem>
: null
           }
{
  cities && cities.length > 0 ? cities.map(d=>(
    <MenuItem sx={{":hover":{backgroundColor:theme.palette.primary.main,color:'white'}}} dir='rtl' value={d.id} key={d.id}>{d.name}</MenuItem>
  ))
  :
  null
}
         </Select>
         {
           touched.city_id && !city_id ? <InputLabel sx={{color:'red'}}>ادخال المدينة اجباري</InputLabel> 
           :
           errorss?.city_id && errorss?.city_id[0]
           ? <InputLabel sx={{color:'red'}}>{errorss?.city_id[0]}</InputLabel> 
           : null
         }
       
      </Box>

      <Box>
        <InputLabel sx={(touched.phone && errors.phone 
          || (errorss?.phone && errorss?.phone[0])
          ) ? {color:'red'} : {}}  className={styles.label}>الجوال </InputLabel>
        <FilledInput
        autoComplete='off'
        // onChange={(e)=>setphone(e.target.value)}
        onChange={handleChange}
        onBlur={handleBlur}
        name='phone'  

        placeholder='الجوال'
         disableUnderline
         sx={(touched.phone && errors.phone)
          || (errorss?.phone && errorss?.phone[0])
          ?  {
          backgroundColor:'white',
          borderRadius:40,
          paddingBottom:1,
          border:'2px solid',
          height:'3rem',
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
        
         } : {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         startAdornment={<InputAdornment position={'start'}><img src={icon25} className={styles.icon}/></InputAdornment>}
         ></FilledInput>
        {
           touched.phone && errors.phone && !values.phone ? 
           <InputLabel sx={{color:'red'}}>{errors.phone}</InputLabel>
           : touched.phone && errors.phone && values.phone ?
           <InputLabel sx={{color:'red'}}>يجب ادخال أرقام</InputLabel>
           : (errorss?.phone && errorss?.phone[0])
           ?
           <InputLabel sx={{color:'red'}}>{errorss?.phone[0]}</InputLabel>
           :null
         }
      </Box>
      </Box>
      </Box>
          </Box>
           </Box>
           <Box flex='1' display='flex' alignItems={{xs:'center',sm:'center',md:'flex-start',lg:'flex-start'}}  flexDirection={{xs:'column',sm:'column',md:'row',lg:'row'}}  marginTop={3}>
           <Box flex='1' display='flex'  alignItems='flex-start'>
             <Box>
               <InputLabel sx={(touched.password && errors.password) || (errorss?.password && errorss?.password[0]) ? {color:'red'} : {}}  className={styles.label}> كلمة المرور</InputLabel>
        <FilledInput 
        // onChange={(e)=>setpassword(e.target.value)}
        onChange={handleChange}
        onBlur={handleBlur}
        name='password' 
        autoComplete={false}
        placeholder=' كلمة المرور'
         disableUnderline
         type={show1 ? 'text' : 'password'}

         sx={(touched.password && errors.password) || (errorss?.password && errorss?.password[0]) ?  {
          backgroundColor:'white',
          borderRadius:40,
          paddingBottom:1,
          border:'2px solid',
          height:'3rem',
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
        
         } : {
          backgroundColor:'white',
          borderRadius:40,
          height:'3rem',
          border:'2px solid',
          paddingBottom:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main

          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         startAdornment={<InputAdornment position={'start'}><Lock sx={{ml:1}} className={styles.icon}/></InputAdornment>}
         endAdornment={<InputAdornment position={'end'}>
           {
             show1 ? <div style={{display:'inline'}} onClick={()=>setshow1(!show1)}><VisibilityOff sx={{mt:2}} className={styles.icon}/></div> 
             :
             <div style={{display:'inline'}} onClick={()=>setshow1(!show1)}><Visibility sx={{mt:2}} className={styles.icon}/></div> 
           }
           </InputAdornment>}

         ></FilledInput>
         <br/>
         {
           touched.password && errors.password ? 
           <InputLabel sx={{color:'red'}}>{errors.password}</InputLabel>
           : errorss?.password && errorss?.password[0] ?
           <FormLabel sx={{color:'red'}}>{errorss?.password[0]}</FormLabel>
           : null

         }
             </Box>
        
      </Box>  
      <Box flex='1' display='flex'  alignItems='flex-start'>
        <Box>
          <InputLabel sx={touched.password_confirmation && errors.password_confirmation ? {color:'red'} : {}}  className={styles.label}>تأكيد كلمة المرور </InputLabel>
        <FilledInput 
        // onChange={(e)=>setpassword_confirmation(e.target.value)}
        onChange={handleChange}
        onBlur={handleBlur}
        autoComplete={false}
        name='password_confirmation' 
        placeholder='تأكيد كلمة المرور'
         disableUnderline
         type={show2 ? 'text' : 'password'}
         sx={touched.password_confirmation && errors.password_confirmation ?  {
          backgroundColor:'white',
          borderRadius:40,
          paddingBottom:1,
          border:'2px solid',
          height:'3rem',
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'},
          borderColor:'red',
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main,
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
          },
          '&.Mui-root':{
            borderWidth:0,
            width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
          }
         } : {
          backgroundColor:'white',
          borderRadius:40,
          paddingBottom:1,
          border:'2px solid',
          height:'3rem',
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderColor:theme.palette.secondary.main,
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}


          },
          '&.Mui-root':{
            borderWidth:0
          },
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         startAdornment={<InputAdornment position={'start'}><Lock sx={{ml:1,fontSize:24,p:5}} className={styles.icon}/></InputAdornment>}
         endAdornment={<InputAdornment position={'end'}>
           {
             show2 ? <div style={{display:'inline'}} onClick={()=>setshow2(!show2)}><VisibilityOff sx={{mt:2}} className={styles.icon}/></div> 
             :
             <div style={{display:'inline'}} onClick={()=>setshow2(!show2)}><Visibility sx={{mt:2}} className={styles.icon}/></div> 
           }
           
           </InputAdornment>}
         ></FilledInput>
        {
           touched.password_confirmation && errors.password_confirmation ? 
           <InputLabel sx={{color:'red',
          width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
          }}>{errors.password_confirmation}</InputLabel>
           : null
         }
        </Box>
        
      </Box>
           </Box>
           <Box sx={{marginRight:'30%',marginTop:5,marginBottom:5}}>
             {
               loading_register ? <CircularProgress />
               :
               <_Button width={{xs:'60%',sm:'60%',lg:'30%',md:'30%'}} height={'50px'} onClick={handleSubmit} fontSize={20}>تسجيل</_Button>
             }
             
                {/* <InputLabel sx={{color:'red'}}>{errorss?.email && errorss?.email[0] ? errorss?.email[0] : null}</InputLabel> */}
                {/* <InputLabel sx={{color:'red'}}>{errorss?.password && errorss?.password[0] ? errorss?.password[0] : null}</InputLabel> */}
                {/* <InputLabel sx={{color:'red'}}>{errorss?.user_name && errorss?.user_name[0] ? errorss?.user_name[0] : null}</InputLabel> */}
                {/* <InputLabel sx={{color:'red'}}>{errorss?.first_name && [errorss?.first_name0] ? errorss?.first_name[0] : null}</InputLabel> */}
                {/* <InputLabel sx={{color:'red'}}>{errorss?.last_name && errorss?.last_name[0] ? errorss?.last_name[0] : null}</InputLabel> */}
                {/* <InputLabel sx={{color:'red'}}>{errorss?.city_id && errorss?.city_id[0] ? errorss?.city_id[0] : null}</InputLabel> */}
                {/* <InputLabel sx={{color:'red'}}>{errorss?.phone && errorss?.phone[0] ? errorss?.phone[0] : null}</InputLabel> */}
                <InputLabel sx={{color:'red'}}>{confirmMsg}</InputLabel>
             
           </Box>
          
           </form> 
            {/* )}
           </Formik> */}
           
       </Container>
    )
}

export default RegisterForm
