import { BorderColor, DeleteOutlined } from '@mui/icons-material'
import { Box, Button, FilledInput, IconButton, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import { theme } from '../../../../theme'

const Nationality = ({
    id,
    name,
    english_name:_english_name,
    DeleteNationality,
    UpdateNationality
}) => {
    const [editMode,setEditMode] = useState(false)
    const [arabic_name,setarabic_name] = useState(name)
    const [english_name,setenglish_name] = useState(_english_name)
    return (
        <Box display="flex" justifyContent="center" columnGap={editMode ? 10 : 1}>
        <Box
          sx={{
            width: 200,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderRadius: 5,
          }}
        >
            {
                editMode ?
                <FilledInput disableUnderline
         sx={{backgroundColor:'transparent',
                textAlign:'center',
                fontSize:24
            }} autoFocus value={english_name} onChange={(e)=>setenglish_name(e.target.value)}/>
                :
             <InputLabel
            sx={{
              fontSize: 24,
              color: theme.palette.secondary.main,  
              mt:1           
            }}
          >
            {name}
          </InputLabel>
            }
             {
                editMode ?
                <FilledInput disableUnderline
         sx={{backgroundColor:'transparent',
                textAlign:'center',
                fontSize:24
            }} autoFocus value={arabic_name} onChange={(e)=>setarabic_name(e.target.value)}/>
                :
             <InputLabel
            sx={{
              fontSize: 24,
              color: theme.palette.secondary.main,  
              mt:1           
            }}
          >
            {english_name}
          </InputLabel>
            }
         
        </Box>
        {
            editMode ? <Button onClick={()=>{
                UpdateNationality({id,arabic_name,english_name})
            }} sx={{fontSize:22}}>حفظ</Button>
            :null
        }
        {
             editMode ? null
             : <IconButton
             color="primary"
             aria-label="upload picture"
             component="span"
             onClick={()=>DeleteNationality(id)}
           >
             <DeleteOutlined
               sx={{
                 m: 1,
                 color: theme.palette.secondary.main,
                 fontSize: 32,
               }}
             />
           </IconButton> 
        }
          {
            editMode ? null
            :  <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={()=>setEditMode(!editMode)}
          >
              
            <BorderColor
              sx={{
                m: 1,
                color: theme.palette.secondary.main,
                fontSize: 32,
              }}
            />
          </IconButton>
        }
       


      </Box>
    )
}

export default Nationality
