import { AddCircle, BorderColor, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormLabel,
  IconButton,
  InputLabel,
  Pagination,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Delete_Evaluation, getEvaluations, Update_Evaluations } from "../../../features/evaluationsSlice";
import { theme } from "../../../theme";
import City from "./components/Evaluation";

const Evaluations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [evaluations, setEvaluations] = useState([]);
  const state = useSelector((state) => state.evaluationsSlice);
  const [page,setPage]=useState(1)
  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    dispatch(getEvaluations({paginate:1,page:page}));
  }, [dispatch,page]);
  useEffect(() => {
    setEvaluations(state.data);
  }, [state.data]);

  const DeleteEvaluation = async (id) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await dispatch(Delete_Evaluation(id)).unwrap();
      console.log({response})
      if (response.success) {
        dispatch(getEvaluations({paginate:1,page:page}));
      }
    }
  };

  const updateEvaluation = async (data) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await dispatch(Update_Evaluations(data)).unwrap();
      if (response.success) {
        dispatch(getEvaluations({paginate:1,page:page}));
      }
    }
  };
  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box dir="rtl" textAlign="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
            }}
          >
            التقييم
          </InputLabel>
          <IconButton
            color="secondary"
            component="span"
            onClick={() => navigate("/dashboard/evaluations/new-evaluation")}
          >
            <AddCircle
              sx={{ olor: theme.palette.secondary.main, fontSize: 32 }}
            />
          </IconButton>
        </Box>
        <Box>
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
              width: "70%",
            }}
          >
            التقييم
          </InputLabel>
          {
            state.loading ? (
              <Box mb={20}>
                  <CircularProgress />
                </Box>
            ) : 
            <Box display='flex' flexDirection='column' rowGap={2} >
          {
          evaluations && evaluations?.data?.length > 0 ? (
            evaluations?.data?.map((evaluation) => (
              <City
                name={evaluation.evaluation}
                start_dateP={evaluation.start_date}
                end_datep={evaluation.end_date}
                id={evaluation.id}
                key={evaluation.id}
                DeleteEvaluation={DeleteEvaluation}
                UpdateEvaluation={updateEvaluation}
              />
            ))
          ) : (
            <label>no data</label>
          )}
          </Box>
          }
          
        </Box>
      </Box>
      <Box alignSelf="center" marginBottom={10} marginTop={5}>
        <Pagination count={state.data.last_page} onChange={handleChange} page={page}/>
      </Box>
    </Box>
  );
};

export default Evaluations;
