
import { Box, FilledInput, InputAdornment, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import _Button from '../../../../components/Button'
import { theme } from '../../../../theme'
import icon35 from '../../../../assets/images/PNG-35.png'
export const Phase1 = ({setPhase,
    setQuestion,
    setQuestion_degree,
    setIs_multiple_answers,
    createQuestionWithAnswers,
    is_multiple_answers,
    question,
    question_degree

}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name,setName] = useState('')
    const [degree,setDegree] = useState('')
    const [type,setType] = useState('single')
    const [message,setMessage]=useState('')
    const {add_data:{success}} = useSelector(state=>state.cities)
    const next = () => {
      if(question == '' || question_degree == ''){
        setMessage('املأ السؤال و الثقل')
      }else{
        setPhase('2')
      }
    }
    return (
        <Box
        style={{
            textAlign:'center',
            alignItems:'center',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            rowGap:20
        }}
        >
            <FilledInput
            value={question}
            onChange={(e)=>{setName(e.target.value);setQuestion(e.target.value);setMessage('')}}
         disableUnderline
         placeholder='السؤال'
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          height:40,
          m:1,
          width:220,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white'
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
        //  startAdornment={<InputAdornment position={'start'}><AccountCircle/></InputAdornment>}
         ></FilledInput>
         <FilledInput
            value={question_degree}
            type='number'
              components={`<input type='number' min='0'/>`}
            onChange={(e)=>{setDegree(e.target.value);setQuestion_degree(e.target.value);setMessage('')}}
         disableUnderline
         placeholder='الثقل'
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          
          height:40,
          m:1,
          width:220,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
        //  startAdornment={<InputAdornment position={'start'}><AccountCircle/></InputAdornment>}
         ></FilledInput>
         <Select
         dir='rtl'
            value={is_multiple_answers}
            onChange={(e)=>{setType(e.target.value);setIs_multiple_answers(e.target.value)}}
         disableUnderline
         placeholder='النوع'
         sx={{
          backgroundColor:'white',
          borderRadius:40,
          alignSelf:'center',
          border:'2px solid',
          height:40,
          width:220,
          m:1,
          borderColor:theme.palette.primary.main,
          '&.Mui-focused':{
            backgroundColor:'white',
            borderWidth:0
          },
          '&.Mui-root':{
            borderWidth:0
          },
        //   width:{xs:'15rem',sm:'15rem',md:'25rem',lg:'25rem'}
         }}
         endAdornment={<InputAdornment position={'end'}><img src={icon35} style={{
          marginTop:2,
          color:theme.palette.primary.main,
          width:'90%'
         }}/></InputAdornment>}
         >
           <MenuItem value='single' dir='rtl'>خيار واحد</MenuItem>
           <MenuItem value='multiple' dir='rtl'>متعدد الخيارات</MenuItem>
           <MenuItem value='custom' dir='rtl'>اخر</MenuItem>
         </Select>
             <_Button width={100} onClick={()=>type == 'custom' ? createQuestionWithAnswers() : next()}>{type == 'custom' ?  "انشاء" : "التالي"}</_Button>
        <p style={{color:'red'}}>{message}</p>
        </Box>
    )
}
