import { AddCircle, BorderColor, DeleteOutlined } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  FormLabel,
  IconButton,
  InputLabel,
  Pagination,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Delete_Nationality, getNationalities, Update_Nationality } from "../../../features/nationalitiesSlice";
import { theme } from "../../../theme";
import Nationality from "./components/Nationality";

const Nationalities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nationalities, setNationalities] = useState([]);
  const state = useSelector((state) => state.nationalities);
  useEffect(() => {
    dispatch(getNationalities());
  }, [dispatch]);
  useEffect(() => {
    setNationalities(state.data);
  }, [state.data]);
  const DeleteNationality = async (id) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await dispatch(Delete_Nationality(id)).unwrap();
      if (response.success) {
        setNationalities(nationalities.filter((nationality) => nationality.id != id));
      }
    }
  };
  const updateNationality = async (data) => {
    let bool = window.confirm("هل انت متأكد؟");
    if (bool) {
      const response = await dispatch(Update_Nationality(data)).unwrap();
      if (response.success) {
        dispatch(getNationalities());
      }
    }
  };
  return (
    <Box display="flex" flexDirection="column" rowGap={2}>
      <Box dir="rtl" textAlign="center">
        <Box display="flex" justifyContent="center" alignItems="center">
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
            }}
          >
            الجنسيات
          </InputLabel>
          <IconButton
            color="secondary"
            component="span"
            onClick={() => navigate("/dashboard/nationalities/new-nationality")}
          >
            <AddCircle
              sx={{ olor: theme.palette.secondary.main, fontSize: 32 }}
            />
          </IconButton>
        </Box>
        <Box>
          <InputLabel
            sx={{
              color: theme.palette.secondary.main,
              fontSize: 24,
              width: "70%",
            }}
          >
            الجنسيات
          </InputLabel>
          {
            state.loading ? (
              <Box mb={20}>
                  <CircularProgress />
                </Box>
            ) : 
            <Box display='flex' flexDirection='column' rowGap={2}>
          {
          nationalities && nationalities?.length > 0 ? (
            nationalities.map((nationality) => (
              <Nationality
                name={nationality.arabic_name}
                english_name={nationality.english_name}
                id={nationality.id}
                key={nationality.id}
                DeleteNationality={DeleteNationality}
                UpdateNationality={updateNationality}
              />
            ))
          ) : (
            <label>no data</label>
          )}
          </Box>
          }
          
        </Box>
      </Box>
      <Box alignSelf="center" marginBottom={10} marginTop={5}>
        <Pagination count={1} />
      </Box>
    </Box>
  );
};

export default Nationalities;
