import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#03888B",
      
    },
    action:{
           hover:'#202B53'
    },
    secondary: {
      main: "#202B53",
    },
  },
  box: {
    primary: {
      main: "#3A8A8A",
    },
    secondary: {
      main: "#3A8A8A",
    },
  },
  typography: {
    fontFamily: "GE_SS",
  },
  primary: {
    main: "#3A8A8A",
  },
});
