import { Box, Container, Divider, FormLabel, InputLabel } from "@mui/material";
import React from "react";
import _Button from "../../../components/Button";
import { theme } from "../../../theme";
import img3 from "../../../assets/images/photo_png1.png";
import VisitorFooter1 from "../../../layout/footer/VisitorFooter1";
import text_1 from "../../../demo/text";
import Item from "./components/Item";
import Img111 from '../../../assets/images/img1111.png'
import Img222 from '../../../assets/images/img2222.png'
import Img333 from '../../../assets/images/img3333.png'
const AboutUs = () => {
  return (
    <Container>
     
        <Box>
            {/* header 1 */}
          <Box dir="rtl" textAlign='center'>
            <InputLabel
              dir="rtl"
              sx={{
                fontSize: 48,
                color: theme.palette.primary.main,
                textAlign: "center",
              }}
            >
              نبذة عن المعهد
            </InputLabel>
            <FormLabel dir="rtl" sx={{color:theme.palette.secondary.main}}>{text_1}</FormLabel>
          </Box>
            {/* header 1 */}

            {/* Body */}
              <Container sx={{justifyContent:'space-evenly',display:'flex',marginTop:10,
            flexDirection:{xs:'column',sm:'column',md:'row',lg:'row'}
            }}>
                      <Item as='flex-start' title={'هدفنا'} img={Img333} txt={`
تحقيق لاعبي الأكاديمية إنجازات إقليمية وقارية وعالمية في كبرى المحافل الرياضية على غرار بطولات كأس العالم ودورة الألعاب الأولمبية.`}/>
                      <Divider orientation='vertical' sx={{mt:{xs:5,sm:5,md:12,lg:12},mb:{xs:5,sm:5,md:0,lg:0},mr:5,ml:5,borderWidth:1,borderColor:theme.palette.secondary.main}} flexItem/>
                      <Item mb={true} as='center' title={'قيمنا'} img={Img222} txt={` 
بناء منظومة اجتماعية راقية و بيئة رياضية صحية تؤمن بمبادئ الرياضة وتزرع روح المنافسة في نفوس الصغار من كلا الجنسين.`}/>
                      <Divider orientation='vertical' light sx={{mt:{xs:5,sm:5,md:12,lg:12},mb:{xs:5,sm:5,md:0,lg:0},mr:5,ml:5,borderWidth:1,borderColor:theme.palette.secondary.main}} flexItem/>
                      <Item ms={true} as='center' title={'رؤيتنا'} img={Img111}  txt={`
تفوق السعودية بتصنيفها المرجع العالمي الأول في اكتشاف وتطوير المواهب ضمن الفئات العمرية مع تصدير أفضل المنهجيات والممارسات الرياضية.`}/>                    
              </Container>
            {/* Body */}


        </Box>

            {/* footer */}
      <Container sx={{marginTop:10}}>
      <VisitorFooter1 /> 
      <Container dir="rtl" alignSelf="flex-end">
        <img
          src={img3}
          width="30%"
          style={{ alignSelf: "flex-end", zIndex: 2, position: "relative" }}
        />
      </Container>
            {/* footer */}

      </Container>
    </Container>
  );
};

export default AboutUs;
