import { Box, Container, Link, Toolbar } from '@mui/material'
import React from 'react'
import {NavLink as RouterLink, useLocation, useNavigate} from 'react-router-dom'
import { theme } from '../../theme'
import Logo_1 from '../../assets/images/Logo_1.png'
import { useSelector } from 'react-redux'

export default function VisitorHeader() {
  const accessToken = useSelector(state=>state.auth?.data?.token)
  const role_name = useSelector(state=>state.auth?.data?.role_name)
  const location = useLocation()
  const navigate = useNavigate()
  const RoleNameArabic = (role) => 
  {
    switch (role) {
      case 'superadmin':
        return 'منصة المشرف العام'
        break;
        case 'coach':
          return 'منصة المدرب'
          break;
          case 'talent':
            return 'منصة المواهب'
            break;
      default:
        break;
    }
  }
  const nav_Links = [
    {title:accessToken ? RoleNameArabic(role_name) : 'الصفحة الرئيسية',url:accessToken ? '/dashboard' : '/'},
    {title:'عن البرنامج',url:'/about-project'},
    // {title:'اخر الاخبار',url:'/news'},
    {title:'تواصل معنا',url:'/contactus'},
    {title:'نبذة عنا',url:'/aboutus'}
  ]
    return (
      <Container dir='rtl' sx={{display:'flex',justifyContent:'space-around'}}>


        <Toolbar
        dir='rtl'
        component="nav"
        variant="dense"
        sx={{ overflowX: 'hiden',height:180}}
      >
        {nav_Links?.map((section,index) => (
          <Link
            style={({isActive})=>isActive ? {color:'white',backgroundColor:theme.palette.primary.main,borderTopRightRadius:index == 0 ? 20 : 0,borderBottomRightRadius:index == 0 ? 20 : 0} : {}}
            component={RouterLink}
            color="inherit"
            
            noWrap
            key={section.title}
            variant="h6"
            to={section.url}
            sx={{fontFamily:'GE_SS', paddingRight:3,paddingLeft:3, flexShrink: 0,color:theme.palette.secondary.main, borderRightWidth:index === 0 ? 0 : '1px',borderRightColor:theme.palette.secondary.main, borderRightStyle:'solid',textDecoration:'none' }}
          >
            {section.title} 
          </Link>
       
        ))}
      </Toolbar>
      {
        location?.pathname == '/'
        ?  null
        :  <Box alignSelf='center' onClick={()=>navigate('/')}><img src={Logo_1} width='50%' style={{alignSelf:'center',marginRight:150}}/></Box>
      }

      </Container>
    )
}
