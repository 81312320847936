import { BorderColor, DeleteOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FilledInput, FormLabel, IconButton, InputLabel, Pagination } from '@mui/material'
import React, { useState } from 'react'
import _Button from '../../../../components/Button'
import { theme } from '../../../../theme'
import API from '../../../../util/API'

const School = ({
  school_id,
    school_name,
    school_city_name,
    school_address,
    manager_firstname,
    manager_lastname,
    talents,
    security,
    janitors,
    coaches,
    coaches_names,
    janitors_names,
    address_name,
    security_names,
    // Deleteschool,
    // Updateschool
}) => {
    const [editMode,setEditMode] = useState(false)
    const [value,setValue] = useState('')
    const [title,setTitle]=useState('')
    const [data,setData]=useState([])
    const [open,setOpen]=useState(false)

    // const [title_talents,setTitle_talents]=useState('')
    // const [data_talents,setData_talents]=useState([])
    const [page,setpage]=useState(1)
    const [count,setCount]=useState(0)
    const handleChange = (event, value) => {
      setpage(value);
      getpaginatetalents(value)
    };

    const [loading,setloading]=useState(false)

    const setOpenDialogue=(title,data)=>{
      setTitle(title)
      setData(data);
      setOpen(true)
    }

    const gettalents_by_skl_id = async()=>{
      setTitle('المواهب')
      setOpen(true)
      setloading(true)
      try{
         let res = await API.get('gettalentsbyschoolid/'+school_id)
      if(res.data.success){
        console.log(res.data.data.data)
        setData(res.data.data.data)
        setCount(res.data.data.last_page)
        setloading(false)
      }
      }catch(e){
        setloading(false)
      }
    }

    const getpaginatetalents=async(value)=>{
      try{
        let res = await API.get(`gettalentsbyschoolid/${school_id}?page=${value}`)
     if(res.data.success){
       console.log(res.data.data.data)
       setData(res.data.data.data)
       setloading(false)
     }
     }catch(e){
       setloading(false)
     }
    }
    return (
        <Box display="flex" justifyContent="center" columnGap={editMode ? 10 : 1}>
          <Dialog disableEscapeKeyDown open={open}>
          <DialogTitle style={{textAlign:'center'}}>{title}</DialogTitle>
          <DialogContent style={{display:'flex',flexDirection:'column'}}>
              {
                title == 'المواهب' && loading ?
                <CircularProgress style={{alignSelf:'center'}}/>
                :
                data.length && data.map(d=>(
                  <FormLabel style={{textAlign:'center'}}>{d.first_name}{' '}{d.last_name}</FormLabel>
                ))
              }
           {title == 'المواهب' ?  <Pagination style={{alignSelf:'center'}} siblingCount={0} page={page} onChange={handleChange} count={count}/>
          :null 
          } 
          </DialogContent>
          <DialogActions dir='rtl'><_Button onClick={()=>setOpen(false)}>أغلق</_Button></DialogActions>
          </Dialog>
       
           {
                editMode ?
                
                <FilledInput disableUnderline
         sx={{backgroundColor:'transparent',
                textAlign:'center',
                fontSize:14
            }} autoFocus value={value} onChange={(e)=>setValue(e.target.value)}/>
                :
                (
                  <>
                <Box
                sx={{
                  width: 150,
                  borderWidth: 2,
                  borderColor: theme.palette.primary.main,
                  borderStyle: "solid",
                  borderRadius:5,
                  minHeight:40,
                  p:'0px 5px'
                }}
              >
             <FormLabel
            sx={{
              fontSize: 14,
              color: theme.palette.secondary.main,  
              mt:1,
                      
            }}
          >
            {school_name}
          </FormLabel>
          </Box>
          </>
                )
            }
            {
                editMode ?
                <FilledInput disableUnderline
         sx={{backgroundColor:'transparent',
                textAlign:'center',
                fontSize:14
            }} autoFocus value={value} onChange={(e)=>setValue(e.target.value)}/>
                :
                <Box
                sx={{
                  width: 200,
                  borderWidth: 2,
                  borderColor: theme.palette.primary.main,
                  borderStyle: "solid",
                  borderRadius:5,
                  height:40
                }}
              >
             <InputLabel
            sx={{
              fontSize: 14,
              color: theme.palette.secondary.main,  
              mt:1           
            }}
          >
            {school_city_name}/{school_address}
          </InputLabel>
          </Box>
            }
            {
                editMode ?
                <FilledInput disableUnderline
         sx={{backgroundColor:'transparent',
                textAlign:'center',
                fontSize:14
            }} autoFocus value={value} onChange={(e)=>setValue(e.target.value)}/>
                :
                <Box
                sx={{
                  width: 200,
                  borderWidth: 2,
                  borderColor: theme.palette.primary.main,
                  borderStyle: "solid",
                  borderRadius:5,
                  height:40
                }}
              >
             <InputLabel
            sx={{
              fontSize: 14,
              color: theme.palette.secondary.main,  
              mt:1           
            }}
          >
            {manager_firstname}{' '}{manager_lastname}
          </InputLabel>
          </Box>
            }   {
              editMode ?
              <FilledInput disableUnderline
       sx={{backgroundColor:'transparent',
              textAlign:'center',
              fontSize:14
          }} autoFocus value={value} onChange={(e)=>setValue(e.target.value)}/>
              :
              <Box
              component='div'
              onClick={()=>setOpenDialogue('المدربين',coaches_names)}
              sx={{
                width: 70,
                borderWidth: 2,
                borderColor: theme.palette.primary.main,
                borderStyle: "solid",
                borderRadius:5,
                height:40
              }}
            >
           <InputLabel
          sx={{
            fontSize: 14,
            color: theme.palette.secondary.main,  
            mt:1           
          }}
        >
          {coaches}
        </InputLabel>
        </Box>
          }   {
            editMode ?
            <FilledInput disableUnderline
     sx={{backgroundColor:'transparent',
            textAlign:'center',
            fontSize:14
        }} autoFocus value={value}
         onChange={(e)=>setValue(e.target.value)}
         />
            :
            <Box
            component='div'
            onClick={()=>setOpenDialogue('عمال النظاقة',janitors_names)}
            sx={{
              width: 70,
              borderWidth: 2,
              borderColor: theme.palette.primary.main,
              borderStyle: "solid",
              borderRadius:5,
              height:40
            }}
          >
         <InputLabel
        sx={{
          fontSize: 14,
          color: theme.palette.secondary.main,  
          mt:1           
        }}
      >
        {janitors}
      </InputLabel>
      </Box>
        }   {
          editMode ?
          <FilledInput disableUnderline
   sx={{backgroundColor:'transparent',
          textAlign:'center',
          fontSize:14
      }} autoFocus value={value} onChange={(e)=>setValue(e.target.value)}/>
          :
          <Box
          component='div'
          onClick={()=>setOpenDialogue('رجال الامن',security_names)}
          sx={{
            width: 70,
            borderWidth: 2,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderRadius:5,
            height:40
          }}
        >
       <InputLabel
      sx={{
        fontSize: 14,
        color: theme.palette.secondary.main,  
        mt:1           
      }}
    >
      {security}
    </InputLabel>
    </Box>
      }   {
        editMode ?
        <FilledInput disableUnderline
 sx={{backgroundColor:'transparent',
        textAlign:'center',
        fontSize:14
    }} autoFocus value={value} onChange={(e)=>setValue(e.target.value)}/>
        :
        <Box
        component='div'
        onClick={gettalents_by_skl_id}
        sx={{
          width: 70,
          borderWidth: 2,
          borderColor: theme.palette.primary.main,
          borderStyle: "solid",
          borderRadius:5,
          height:40
        }}
      >
     <InputLabel
    sx={{
      fontSize: 14,
      color: theme.palette.secondary.main,  
      mt:1           
    }}
  >
    {talents}
  </InputLabel>
  </Box>
    }  
        {
            editMode ? <Button
            //  onClick={()=>{
            //     UpdateCity({id,name:value})
            // }} 
            sx={{fontSize:22}}>حفظ</Button>
            :null
        }
        {
             editMode ? null
             : <IconButton
             color="primary"
             aria-label="upload picture"
             component="span"
            //  onClick={()=>DeleteCity(id)}
           >
             <DeleteOutlined
               sx={{
                
                 color: theme.palette.secondary.main,
                 fontSize: 32,
               }}
             />
           </IconButton> 
        }
          {
            editMode ? null
            :  <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            // onClick={()=>setEditMode(!editMode)}
          >
              
            <BorderColor
              sx={{
           
                color: theme.palette.secondary.main,
                fontSize: 32,
              }}
            />
          </IconButton>
        }
       


      </Box>
    )
}

export default School
