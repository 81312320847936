import React from 'react';
import RoutesNavigation from './navigation/RoutesNavigation';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
// import './App.module.css'

function App() {
  return (
    <div>
   
            <ThemeProvider theme={theme}>
            <CssBaseline/>
      <BrowserRouter>
      <RoutesNavigation />
      </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
