import React from 'react'
import {
    FacebookOutlined,
    Instagram,
    Twitter,
    WhatsApp,
    YouTube,
  } from "@mui/icons-material";
import { Box, Hidden } from '@mui/material';
import { theme } from '../../theme';
import Logo from '../../assets/images/PNG-48.png'
const SocialMedia = () => {
    return (
        <div style={{position:'absolute',bottom:10,right:10}}>
            <Hidden smDown mdDown>
            <div dir='rtl' > 
            <img src={Logo} width={210}/>
   <Box width={300} display='flex' columnGap={2}>
                <FacebookOutlined sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <Instagram sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <Twitter sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <WhatsApp sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <YouTube sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
            </Box> 
        </div>
            </Hidden>
        <Hidden mdUp>
             <div dir='rtl' style={{position:'fixed',bottom:10,right:15}}>
            <img src={Logo} width={180}/>
   <Box width={200} display='flex' columnGap={1}>
                <FacebookOutlined sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <Instagram sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <Twitter sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <WhatsApp sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
                <YouTube sx={{backgroundColor:theme.palette.primary.main,color:'white',borderRadius:10,padding:1,fontSize:34}}/>
            </Box> 
        </div>
        </Hidden>
       

        </div>
    )
}

export default SocialMedia
