import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../util/API";

const initialState = {
  data: [],
  loading: false,
  error: false,
  delete_data: {
    success: "",
    message: "",
  },
  add_data: {},
};

export const getShcools = createAsyncThunk("schoolsSlice/get", async (data) => {
  try {
    let res = await API.get("getschoolsdetails");
    if (res.data.success) {
      return res.data.data;
    }
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    console.log(err);
  }
});

export const Delete_City = createAsyncThunk(
  "schoolsSlice/delete",
  async (id) => {
    try {
      let res = await API.put("deletecity/" + id);
      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
    }
  }
);

export const add_City = createAsyncThunk("schoolsSlice/add", async (name) => {
  try {
    let res = await API.post("addcity",name,{headers:{Authorization:"Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw"}});
    if (res.data.success) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    console.log(err);
  }
});

export const Update_city = createAsyncThunk(
  "schoolsSlice/update",
  async (data) => {
    console.log({ data });
    try {
      let res = await API.post(
        "editcity/" + data.id,
        { name: data.name },
        {
          headers: {
            Authorization: "Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw",
          },
        }
      );
      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
    }
  }
);

export const schoolsSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: {
    [getShcools.pending]: (state) => {
      state.loading = true;
      state.error = false;
      state.add_data.success=false;
    },
    [getShcools.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getShcools.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [Delete_City.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [Delete_City.fulfilled]: (state, action) => {
      state.delete_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [Delete_City.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [Update_city.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [Update_city.fulfilled]: (state, action) => {
      state.update_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [Update_city.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [add_City.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [add_City.fulfilled]: (state, action) => {
      state.add_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [add_City.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {} = schoolsSlice.actions;

export default schoolsSlice.reducer;
