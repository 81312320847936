import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../util/API";

const initialState = {
  data: [],
  loading: false,
  error: false,
  delete_data: {
    success: "",
    message: "",
  },
  add_data: {},
};

export const getNationalities = createAsyncThunk("nationalitiesSlice/get", async (id) => {
  try {
    let res = await API.get("getnationalities");
    if (res.data.success) {
      return res.data.data;
    }
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    console.log(err);
  }
});

export const Delete_Nationality = createAsyncThunk(
  "nationalitiesSlice/delete",
  async (id) => {
    try {
      let res = await API.put("deletenationality/" + id);
      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
    }
  }
);

export const add_Nationality = createAsyncThunk("nationalitiesSlice/add", async (data) => {
  try {
    let res = await API.post("addnationality",data,{headers:{Authorization:"Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw"}});
    if (res.data.success) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      throw err;
    }
    console.log(err);
  }
});

export const Update_Nationality = createAsyncThunk(
  "nationalitiesSlice/update",
  async (data) => {
    console.log({ data });
    try {
      let res = await API.post(
        "editnationality/" + data.id,
        { arabic_name: data.arabic_name,english_name:data.english_name },
        {
          headers: {
            Authorization: "Bearer 98|g8IbkYUvmO8hGymjfAEO3EZ1cX7wgFI6e3mQ1Mgw",
          },
        }
      );
      if (res.data.success) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      console.log(err);
    }
  }
);

export const nationalitiesSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: {
    [getNationalities.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getNationalities.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [getNationalities.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [Delete_Nationality.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [Delete_Nationality.fulfilled]: (state, action) => {
      state.delete_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [Delete_Nationality.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [Update_Nationality.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [Update_Nationality.fulfilled]: (state, action) => {
      state.update_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [Update_Nationality.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    [add_Nationality.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [add_Nationality.fulfilled]: (state, action) => {
      state.add_data = action.payload;
      state.loading = false;
      state.error = false;
    },
    [add_Nationality.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {} = nationalitiesSlice.actions;

export default nationalitiesSlice.reducer;
